<template>
    <div id="notify">
        <div
            v-for="notif in queue"
            :key="notif.uid"
            class="mr ui icon message"
            :class="notif.type"
            @click="notif.expire()"
        >
            <i
                class="circle icon"
                :class="notif.icon"
            />

            <div class="content">
                <div class="header">
                    {{ capitalize(notif.msg) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NotifyService from '@serv/NotifyService'

// Initialize a singleton Notify service with the current Vue instance.
// The `data` must have a `queue` object.
export default {
    name: 'NotifyMsg',
    data() {
        return {
            queue: []
        }
    },
    mounted() {
        NotifyService.init(this.queue)
    }
}
</script>

<style lang="scss">
@import '../styles/colors';
@import '../styles/abstracts/variables';
@import '../styles/abstracts/mixins';

#notify {
    position: fixed;
    width: 409px;
    max-width: calc(100vw - 32px);
    top: 16px;
    right: 16px;
    z-index: $notifyZIndex;

    @include resp-up(md) {
        top: 34px;
        right: 31px;
    }

    .mr.ui.message {
        cursor: pointer;

        &.success {
            color: $green;
            background-color: $lightGreen;
            border-color: $green;
            box-shadow: 0 0 0 1px $green inset;

            .header,
            .icon {
                color: $green;
            }
        }

        &.error {
            color: $red;
            background-color: $lightRed;
            border-color: $red;
            box-shadow: 0 0 0 1px $red inset;

            .header,
            .icon {
                color: $red;
            }
        }
    }
}
</style>
