const _CorinAuthSystemConfig = {
    loadFrameTimeout: 6000
}
const _CorinAuthCacheConfig = {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
}
const CorinAuthConfig = {
    loginConfig: {
        scopes: ['openid', 'profile']
    },
    userApi: {
        local: 'https://corinapim-sit.azure-api.net/CorinIntegrationServices/user-management/users/',
        qa: 'https://corinapim-sit.azure-api.net/CorinIntegrationServices/user-management/users/',
        staging: 'https://corinapim-sit.azure-api.net/CorinIntegrationServices/user-management/users/',
        production: 'https://integrationapis.azure-api.net/CorinIntegrationServices/user-management/users/'
    },
    tokenConfig: {
        local: {
            scopes: [
                'https://CorinADB2CSIT.onmicrosoft.com/cis/Read',
                'https://CorinADB2CSIT.onmicrosoft.com/cis/user_impersonation'
            ],
            sid: 'thisisnotused',
            account: 123456789
        },
        qa: {
            scopes: [
                'https://CorinADB2CTest.onmicrosoft.com/cis/Read',
                'https://CorinADB2CTest.onmicrosoft.com/cis/user_impersonation'
            ],
            sid: 'thisisnotused',
            account: 123456789
        },
        staging: {
            scopes: [
                'https://CorinADB2CSIT.onmicrosoft.com/cis/Read',
                'https://CorinADB2CSIT.onmicrosoft.com/cis/user_impersonation'
            ],
            sid: 'thisisnotused',
            account: 123456789
        },
        production: {
            scopes: [
                'https://CorinauthADB2C.onmicrosoft.com/cis/Read',
                'https://CorinauthADB2C.onmicrosoft.com/cis/user_impersonation'
            ],
            sid: 'thisisnotused',
            account: 123456789
        }
    },
    forgotPassword: {
        local: 'https://corinops-sit.azurewebsites.net/Account/ForgotPassword',
        qa: 'https://corinops-sit.azurewebsites.net/Account/ForgotPassword',
        staging: 'https://corinops-sit.azurewebsites.net/Account/ForgotPassword',
        production: 'https://portal.coringroup.com/Account/ForgotPassword'
    },
    local: {
        auth: {
            clientId: '1bce749d-a633-426c-8716-13846b35b48c',
            authority: 'https://corinadb2csit.b2clogin.com/tfp/CorinADB2CSIT.onmicrosoft.com/B2C_1_SignIn',
            redirectUri: 'http://local.myrecoverytest.com',
            validateAuthority: false
        },
        cache: _CorinAuthCacheConfig,
        system: _CorinAuthSystemConfig
    },
    qa: {
        auth: {
            clientId: 'aeeabb34-f063-46be-9c4e-55146689af01',
            authority: 'https://CorinADB2CTest.b2clogin.com/CorinADB2CTest.onmicrosoft.com/B2C_1_SignIn',
            redirectUri: 'https://corinrpm-qa.myrecovery.ai',
            validateAuthority: false
        },
        cache: _CorinAuthCacheConfig,
        system: _CorinAuthSystemConfig
    },
    staging: {
        auth: {
            clientId: 'b41cbec0-a5f9-443c-aebe-6c63f01b21c6',
            authority: 'https://corinadb2csit.b2clogin.com/tfp/CorinADB2CSIT.onmicrosoft.com/B2C_1_SignIn',
            redirectUri: 'https://corin-staging.myrecovery.ai',
            validateAuthority: false
        },
        cache: _CorinAuthCacheConfig,
        system: _CorinAuthSystemConfig
    },
    production: {
        auth: {
            clientId: '5a2c0bb7-c399-4c6c-8c6c-caeab8197ca3',
            authority: 'https://corinauthadb2c.b2clogin.com/tfp/CorinAuthADB2C.onmicrosoft.com/B2C_1_SignIn',
            redirectUri: 'https://rpm.coringroup.com',
            validateAuthority: false
        },
        cache: _CorinAuthCacheConfig,
        system: _CorinAuthSystemConfig
    }
}

export default Object.freeze(CorinAuthConfig)
