import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import moment from 'moment'
import Schedule from '@model/Schedule'
import StringHelper from '@serv/StringHelper'
import Utils from '@serv/Utils'

class ListTransformAppointment extends ListTransform {
    constructor(object) {
        super(object)
        this.todayMoment = object.nowMoment
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: [],
            addAll: true
        }
        if (this.column.filter?.schedules) {
            this.column.filter.schedules.forEach(scheduleSlug => {
                config.valueLabels.push({
                    value: scheduleSlug,
                    label: StringHelper.capitalize(Locale.getLanguageItem(scheduleSlug))
                })
            })
        }

        return config
    }

    getQueryParamValueFromKeyStrings(keyStrings) {
        if (keyStrings.length > 0) {
            const filter = keyStrings[0]
            const schedule = Schedule.get(filter)
            const startDate = new moment().subtract(schedule.endOffset - 1, 'days').format(Utils.serialisedDateFormat)
            const endDate = new moment().subtract(schedule.startOffset - 1, 'days').format(Utils.serialisedDateFormat)

            const dateRange = {
                startDate: startDate,
                endDate: endDate
            }

            return { dateRange }
        }
    }
}

export default ListTransformAppointment
