// jQuery is required for Semantic UI
import jQuery from 'jquery'
Object.assign(globalThis, { $: jQuery, jQuery })

import * as am4core from '@amcharts/amcharts4/core'
import App from './App.vue'
import ChildForm from '@comp/ChildForm.vue'
import ColorsConfig from '@config/Colors'
import Config from '@serv/Config'
import ConfigManager from '@config/ConfigManager'
import { createApp } from 'vue'
import eventBus from '@comp/EventBus'
import FormContainer from '@comp/FormContainer.vue'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import { registerPlugins } from '@/plugins'
import ValidatedInput from '@comp/ValidatedInput.vue'
import ValidationObserver from '@comp/ValidationObserver.vue'
import { Field as ValidationProvider } from 'vee-validate'
import Validators from '@serv/Validators'
import Vue2CompatabilityMixin from '@mixins/Vue2CompatabilityMixin'
import 'mr-semantic-ui/dist/semantic.min.js'
import '@/styles/tailwind.css'
import '@/styles/main.scss'

import.meta.glob('./data/**/*.json', { eager: true })

await Locale.autoSetLocale()

const app = createApp(App)

// replaces Vue.use calls
registerPlugins(app)

app.component('FormContainer', FormContainer)
app.component('ChildForm', ChildForm)
app.component('ValidationProvider', ValidationProvider)
app.component('ValidationObserver', ValidationObserver)
app.component(ValidatedInput.name, ValidatedInput)

app.mixin(Vue2CompatabilityMixin)

const debugEnabled = !!localStorage?.debug || !['production', 'spoof'].includes(Config.environment)
app.config.performance = debugEnabled
Config.debugEnabled = debugEnabled

app.config.errorHandler = err => {
    Logging.error(err)
}

// amCharts
am4core.options.autoDispose = true
am4core.options.commercialLicense = true

/**
 * Vee Validate
 *
 * Load custom field validation messages here to be kept globally
 * https://baianat.github.io/vee-validate/guide/messages.html#field-specific-custom-messages
 */
Validators.setCustomValidation()

window.$bus = eventBus

app.provide('$set', (object: Record<string, unknown>, property: string, value: unknown) => {
    object[property] = value
})
app.provide('$delete', (object: Record<string, unknown>, property: string) => {
    delete object[property]
})
app.provide('$bus', eventBus)
app.provide('$config', ConfigManager.config)
app.provide('$env', Config.environment)
app.provide('$colors', ColorsConfig)

app.mount('#app')

// JS interface
// The Core module has a static interface
import CoreI from '@serv/Core'
window.Core = CoreI
// All other modules have the same singleton interface
import JourneyManagerI from '@serv/JourneyManager'
window.JourneyManager = {
    get: function () {
        return JourneyManagerI.get()
    }
}
import ResultsManagerI from '@serv/ResultsManager'
window.ResultsManager = {
    get: function () {
        return ResultsManagerI.get()
    }
}
import TaskHandlerI from '@serv/TaskHandler'
window.TaskHandler = {
    get: function () {
        return TaskHandlerI.get()
    }
}
import TaskUiI from '@serv/TaskUi'
window.TaskUi = {
    get: function () {
        return TaskUiI.get()
    }
}
import UserManagerI from '@serv/UserManager'
window.UserManager = {
    get: function () {
        return UserManagerI.get()
    }
}
