<template>
    <div class="mr:fixed mr:w-screen mr:h-screen mr:z-(--modal-z-index) mr:top-0 mr:left-0 mr:text-base-grey-5">
        <div
            class="mr:fixed mr:w-full mr:h-full mr:bg-black/65"
            data-cy="mr-popup-overlay"
        />

        <div
            :class="['mr:absolute mr:flex mr:flex-col mr:rounded mr:bg-white mr:max-h-screen-height', contentClass]"
            data-cy="mr-popup-wrapper"
        >
            <div
                class="mr:py-12 mr:px-15 mr:text-center mr:bg-base-grey-5/10"
                data-cy="mr-popup-header"
            >
                <img
                    class="mr:absolute mr:top-5 mr:right-5 mr:close mr:cursor-pointer"
                    :src="getIconUrl('icon-clos-acti.svg')"
                    :alt="lang('popupAltTextCloseButton')"
                    data-cy="mr-popup-close"
                    @click="$emit('close')"
                />

                <div
                    class="mr:font-omnes-medium mr:text-32"
                    data-cy="mr-popup-title"
                >
                    <slot name="title">{{ title }}</slot>
                </div>
            </div>

            <div
                :class="[
                    'mr:flex-1 mr:flex mr:flex-col mr:overflow-hidden mr:pt-10 mr:pb-12',
                ]"
                data-cy="mr-popup-body-wrapper"
            >
                <div
                    v-if="hasSlot('summary')"
                    data-cy="mr-popup-summary"
                    :class="[
                        'mr:font-medium mr:flex mr:justify-between mr:pb-6 mr:font-omnes-medium',
                       summaryClass
                    ]"
                >
                    <slot name="summary" />
                </div>

                <div
                    v-if="hasSlot('body')"
                    :class="[
                        'mr:flex mr:grow mr:flex-col mr:overflow-y-auto mr:overflow-x-hidden mr:pb-6',
                        !props.noPadding ?  'mr:px-20' : ''
                    ]"
                    data-cy="mr-popup-body"
                >
                    <slot name="body" />
                </div>

                <div
                    v-if="hasSlot('footer')"
                    :class="[
                        'mr:text-center ',
                        !props.noPadding ?  'mr:px-20' : ''
                    ]"
                    data-cy="mr-popup-footer"
                >
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { lang } from '@comp/Filters'
import useImage from '@composables/useImage'
import { computed, defineEmits, useSlots } from 'vue'

interface Props {
    noPadding?: boolean,
    title?: string
    width?: 'auto' | 'regular' | 'wide' | 'full-screen'
}

defineEmits(['close'])
const props = withDefaults(defineProps<Props>(), {
    noPadding: false,
    title: '',
    width: 'regular'
})
const { getIconUrl } = useImage()
const slots = useSlots()
const hasSlot = (name: string) => !!slots[name]

const contentClass = computed(() => {
    let contentClass = 'mr:top-1/2 mr:left-1/2 mr:-translate-x-1/2 mr:-translate-y-1/2'

    switch (props.width) {
        case 'wide':
            return `${contentClass} mr:w-modal-wide`
        case 'auto':
            return `${contentClass} mr:max-w-9/10`
        case 'full-screen':
            return `${contentClass} mr:w-9/10`
        default:
            return `${contentClass} mr:w-modal-regular`
    }
})
const summaryClass = computed(() => {
    let summaryClass = !props.noPadding ? 'mr:px-20' : ''

    if (props.width === 'full-screen') {
        summaryClass = 'mr:px-8'
    }

    return summaryClass
})
</script>
