import Clinician from '@model/Clinician'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging.js'

/*
State to manage web registration.
*/
const state = {
    isWebRegistration: undefined, // set to true for all pages as part of patient web registration process
    isWebRegistered: false, // set to true only if we successfully register as part of this session
    isSelfRegistration: undefined, // set to true for self-registration only
    isStartingAtFrontPage: undefined, // set to true only if coming from any front page
    isNhs: undefined, // set to true only if coming from NHS front page
    hospitalNumberAuthType: undefined, // defined only if starting at hospital number auth page
    // Form properties
    clinician: undefined,
    bannerUrl: undefined,
    logoUrl: undefined,
    selfRegistrationFormData: undefined,
    ownerConfig: undefined,
    didComeFromUrlWithToken: undefined
}

const getters = {
    isWebRegistration: state => {
        return state.isWebRegistration
    },
    isWebRegistered: state => {
        return state.isWebRegistered
    },
    isSelfRegistration: state => {
        return state.isSelfRegistration
    },
    isStartingAtFrontPage: state => {
        return state.isStartingAtFrontPage
    },
    hospitalNumberAuthType: state => {
        return state.hospitalNumberAuthType
    },
    isNhs: state => {
        return state.isNhs
    },
    clinician: state => {
        return state.clinician
    },
    bannerUrl: state => {
        return state.bannerUrl
    },
    logoUrl: state => {
        return state.logoUrl
    },
    selfRegistrationFormData: state => {
        return state.selfRegistrationFormData
    },
    ownerConfig: state => {
        return state.ownerConfig
    }
}

const actions = {}

function _getBlobUrlForUuid(uuid) {
    const staticContentUrl = import.meta.env.VITE_STATIC_CONTENT_URL || ''

    if (!staticContentUrl) {
        Logging.error('Static content CDN url not set')

        return ''
    }

    return staticContentUrl.replace('{uuid}', uuid)
}

const mutations = {
    setIsWebRegistration(state, value) {
        state.isWebRegistration = value
    },
    setIsWebRegistered(state, value) {
        state.isWebRegistered = value
    },
    setIsStartingAtFrontPage(state, value) {
        state.isStartingAtFrontPage = value
    },
    setDidComeFromUrlWithToken(state, value) {
        state.didComeFromUrlWithToken = value
    },
    setIsNhs(state, value) {
        state.isNhs = value
    },
    setHospitalNumberAuthType(state, value) {
        state.hospitalNumberAuthType = value
    },
    // Set state from a patient registration deeplink request.
    setPatientRegistrationResponseData(state, data) {
        if (data.surgeon) {
            state.clinician = new Clinician({
                title: data.surgeon.title,
                firstName: data.surgeon.firstName,
                lastName: data.surgeon.lastName
            })
        }
        // Determine the banner URL and potentially logo URL.
        if (data.bannerCompositeUuid) {
            // Composite banner, no logo
            state.bannerUrl = _getBlobUrlForUuid(data.bannerCompositeUuid)
            state.logoUrl = undefined
        }
    },
    // Set state from a patient self-registration request.
    setPatientSelfRegistrationResponseData(state, data) {
        state.clinician = new Clinician({
            userId: data.id || undefined,
            title: data.title,
            firstName: data.firstName,
            lastName: data.lastName,
            slugPrefix: data.slugPrefix
        })
        // Determine the banner URL and potentially logo URL.
        if (data.bannerCompositeUuid) {
            // Composite banner, no logo
            state.bannerUrl = _getBlobUrlForUuid(data.bannerCompositeUuid)
            state.logoUrl = undefined
        }
        state.isSelfRegistration = true
    },
    setPatientSelfRegistrationFormData(state, data) {
        state.selfRegistrationFormData = data
    },
    appendPatientSelfRegistrationFormData(state, data) {
        state.selfRegistrationFormData = { ...state.selfRegistrationFormData, ...data }
    },
    setOwnerConfig(state, data) {
        state.ownerConfig = data

        // Pass any string map values to Locale
        if (state.ownerConfig.keyValues?.stringMap) {
            Locale.stringMap = state.ownerConfig.keyValues.stringMap
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
