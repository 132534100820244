<template>
    <SurveyStepWrapper>
        <template
            v-if="taskHandler.isIntroStep"
            #content
        >
            <div class="text-center">
                <v-btn
                    color="primary"
                    data-cy="survey-info-step-button"
                    rounded
                    size="large"
                    @click="onButton"
                >
                    {{ buttonText }}
                </v-btn>
            </div>
        </template>

        <template #header>
            <SurveyInfoStepOutroHeader
                v-if="taskHandler.isOutroStep"
                :view-model="outroStepViewModel"
                :step="taskHandler.currentStep"
                :step-result="taskHandler.currentStepResult"
            />
        </template>

        <template #bottomText>
            <MrMarkdown
                v-if="bottomText"
                class="survey-step-info__bottom-text pa-4 pa-lg-8"
                :class="{ 'text-center': taskHandler && taskHandler.isOutroStep }"
                :html="true"
                :source="bottomText"
            />

            <div
                v-if="taskHandler.isOutroStep"
                v-show="display.smAndDown.value"
                class="d-flex justify-center"
            >
                <v-btn
                    color="primary"
                    data-cy="survey-info-step-button"
                    rounded
                    size="large"
                    @click="onButton"
                >
                    {{ buttonText }}
                </v-btn>
            </div>
        </template>
    </SurveyStepWrapper>
</template>

<script setup>
import { computed } from 'vue'
import Locale from '@serv/Locale'
import MrMarkdown from '@comp/MrMarkdown.vue'
import SurveyInfoStepOutroHeader from '@comp/survey/SurveyInfoStepOutroHeader.vue'
import SurveyStepWrapper from '@comp/survey/SurveyStepWrapper.vue'
import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'

// intro step actions
const emits = defineEmits('nextStep')
const store = useStore()
const taskHandler = computed(() => store.getters.taskHandler)

const bottomText = computed(() => {
    if (taskHandler.value.isOutroStep) {
        if (taskHandler.value.numSkippedSteps > 0) {
            return Locale.getLanguageItem('patientSurveyOutroFooterSkipped', [
                taskHandler.value.numSkippedSteps,
                Locale.getLanguageItem('patientSurveyNavPrev')
            ])
        }

            return Locale.getLanguageItem('patientSurveyOutroFooter', [
                Locale.getLanguageItem('patientSurveyNavSubmit'),
                Locale.getLanguageItem('patientSurveyNavPrev')
            ])

    }

    return ''
})

const buttonText = computed(() => {
    if (taskHandler.value.isOutroStep) {
        return Locale.getLanguageItem('submit')
    }

    return taskHandler.value.currentStep.keyValues.buttonText || Locale.getLanguageItem('ok')
})

// outro step data
const display = useDisplay()

const outroStepViewModel = computed(() => taskHandler.value.getOutroHeaderViewModel())

const onButton = () => {
    if (taskHandler.value.isLastStep) {
        emits('nextStep')
    } else {
        taskHandler.value.nextStep()
    }
}
</script>
