<template>
    <div
        class="position-relative survey-slider mr:rounded-lg mr:bg-light-grey"
        :class="{
            'survey-slider--vertical mr:p-6': isVertical,
            'survey-slider--horizontal mr:px-6 mr:py-6 mr:md:py-20': !isVertical,
            'survey-slider--small mr:p-4 mr:md:p-6': props.isSmall
        }"
        data-cy="survey-slider"
    >
        <div
            class="survey-slider__value mr:text-center mr:text-brand-a"
            :class="{
            'mr:w-full': !isVertical,
            'mr:absolute mr:left-0 mr:top-1/2 mr:w-1/2 mr:-translate-y-1/2': isVertical,
            'survey-slider__value--formatted': format(value) !== value
        }"
            data-cy="survey-slider-current-value"
        >
            {{ format(value) }}
        </div>

        <div
            class="mr:items-center"
            :class="{ 'mr:flex mr:flex-col mr:pb-0': isVertical, 'mr:mt-8 :mr:md-12': !isVertical }"
        >
            <div
                v-if="isVertical"
                class="survey-slider__limit-description mr:mb-4 mr:uppercase"
            >
                {{ getLangItem('contentHealthTopScoreText') }}
            </div>

            <div
                class="survey-slider__range mr:w-full mr:relative"
                :class="{ 'mr:flex mr:flex-col-reverse mr:items-center': isVertical }"
            >
                <div
                    class="survey-slider__limit survey-slider__limit--min mr:px-2"
                    :class="{
                        'mr:text-start mr:top-full': !isVertical,
                        'mr:max-w-full mr:text-brand-a': isVertical
                    }"
                    data-cy="survey-slider-min-value"
                >
                    {{ currentStep.sliderLeftText || values.min }}
                </div>

                <MrSlider
                    v-if="values.min !== undefined && values.max !== undefined"
                    class="survey-slider__slider"
                    :class="{'mr:[&>div]:min-h-[144px] mr:md:[&>div]:min-h-[173px]': isVertical}"
                    :step-size="stepSize"
                    :min="values.min"
                    :max="values.max"
                    :model-value="value"
                    :direction="direction"
                    @update:model-value="onChange"
                />

                <div
                    class="survey-slider__limit survey-slider__limit--max mr:px-2"
                    :class="{
                    'mr:text-end': !isVertical,
                    'mr:max-w-full mr:text-brand-a': isVertical
                }"
                    data-cy="survey-slider-max-value"
                >
                    {{ currentStep.sliderRightText || values.max }}
                </div>
            </div>

            <div
                v-if="isVertical"
                class="survey-slider__limit-description mr:mt-4 mr:uppercase"
            >
                {{ getLangItem('contentHealthBottomScoreText') }}
            </div>
        </div>
    </div>
</template>

<script setup>
import MrSlider from '@comp/MrSlider.vue'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
    min: {
        type: Number,
        default: 0
    },
    max: {
        type: Number,
        default: 0
    },
    initialValue: {
        type: [Number, undefined],
        default: undefined
    },
    format: {
        type: Function,
        default: (v) => v
    },
    isQuestionStepFreeTextValue: {
        type: Boolean,
        default: false
    },
    direction: {
        type: String,
        default: 'horizontal',
        validator: (direction) => ['horizontal', 'vertical'].includes(direction)
    },
    stepSize: {
        type: Number,
        default: 1
    },
    isSmall: {
        type: Boolean,
        default: false
    }
})

// initialize slider with min and max values
const value = ref(0)
const values = ref({ min: undefined, max: undefined })
const isVertical = computed(() => props.direction === 'vertical')

const store = useStore()
const taskHandler = computed(() => store.getters.taskHandler)
const currentStep = computed(() => taskHandler.value.currentStep)

onMounted(() => {
    values.value = {
        min: props.min || currentStep.value.sliderMinValue,
        max: props.max || currentStep.value.sliderMaxValue
    }

    init()
})

const getPrecisedValue = (value) => {
    const precision = Math.ceil(Math.log10(1 / props.stepSize))

    return parseFloat((Math.round(value / props.stepSize) * props.stepSize).toFixed(precision))
}

const getInitialValue = (min, max) => {
    if (props.initialValue && props.initialValue > props.min && props.initialValue < props.max) {
        return props.initialValue
    }

    // if no initial value is provided, set the middle value
    let middle = (min + max) / 2
    let rounded = Math.round(middle / props.stepSize) * props.stepSize

    return getPrecisedValue(rounded)
}

const init = () => {
    if (props.isQuestionStepFreeTextValue) {
        const number = parseFloat(taskHandler.value.currentStepResult.freeTextValue)
        if (isNaN(number)) {
            value.value = getInitialValue(values.value.min, values.value.max)
        } else {
            value.value = number
        }
    } else {
        if (taskHandler.value.currentStepResult.value == undefined) {
            value.value = getInitialValue(values.value.min, values.value.max)
        } else {
            value.value = taskHandler.value.currentStepResult.value
        }
    }

    value.value = +value.value
}

// actions onChange value
const onChange = (newValue) => {
    value.value = getPrecisedValue(newValue)

    if (props.isQuestionStepFreeTextValue) {
        taskHandler.value.currentStepResult.freeTextValue = newValue.toString()
    } else {
        taskHandler.value.currentStepResult.value = newValue
    }
    taskHandler.value.calcNextButtonStatus()
}
</script>
