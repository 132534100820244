import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import ResourceService from '@serv/ResourceService'
import store from '@src/store/index'

/**
 * List filter for procedure column.
 */
class ListTransformProcedure extends ListTransform {
    constructor(object) {
        super(object)
        // For PatientJourneyList
        if (object.filterSetKey == 'patientJourneyList') {
            this.filterKey = 'procedureTitles'
            this.procedureTitles = ResourceService.getProcedureTitles(this.column)
        }
    }

    get getFilterComponent() {
        return 'DataFilterStringArray'
    }

    get getFilterConfig() {
        // For PatientJourneyList
        if (this.filterSetKey == 'patientJourneyList') {
            const valueLabels = this.procedureTitles.map(procedureTitle => {
                return {
                    value: procedureTitle,
                    label: procedureTitle
                }
            })

            return {
                title: Locale.getLanguageItem('formJourneys'),
                valueLabels: valueLabels.sort((a, b) => a.label.localeCompare(b.label))
            }
        }
        // For PatientList
        const journeys = Object.values(store.state.resources.journeys)
        const procedureCodes = [
            // Use Set to remove dupes, e.g. if there are legacy journeys with same procedureCode
            ...new Set(journeys.map(journey => journey.procedureCode))
        ].filter(c => c)
        const valueLabels = procedureCodes.map(value => {
            return { value: value, label: value }
        })
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: valueLabels.sort((a, b) => a.label.localeCompare(b.label))
        }

        return config
    }

    /**
     * Filter and return the list of rows (Patients), based on an array of input strings.
     * Default is an exact string match of the previously-resolved cellText.
     */
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        // const columnIndex = this.column.columnIndex
        const journeysMap = store.state.resources.journeys

        return rows.filter(row => {
            const pathwayProcedureCodes = row.pathwayJourneys.map(patientJourney => {
                const journey = journeysMap[patientJourney.journeySlug]

                // Deal with patients that have journeys no longer in our list
                return journey ? journey.procedureCode : ''
            })

            return filterStrings.some(filterString => pathwayProcedureCodes.includes(filterString))
        })
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        const includeProcedureLabels =
            !this.column?.filterExcludeProcedureLabels || this.column?.qualifier === 'onlyJourneys'
        const values = ResourceService.getJourneysProcedureTitlesAndLabelPairs(keyStrings, includeProcedureLabels)

        return isGoddard ? { valuesCollection: values } : values
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'procedure_code'
    }
}

export default ListTransformProcedure
