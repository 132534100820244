import Auth from '@serv/Auth'
import Config from '@serv/Config'
import Logging from '@serv/Logging'
import Storage from '@serv/Storage'
import Whoami from '@serv/Whoami'

class UnauthenticatedAuth {
    constructor() {
        this.session = {}
    }

    doAuth() {
        if (!this.session.token || !this.session.region) {
            Logging.error('Token and Region are required for Unauthenticated auth')

            return Promise.reject()
        }

        Auth._setHeader(this.session.token)
        Config.configure(this.session.region)

        return Whoami.getActiveUser()
    }

    hasSession() {
        if (Storage.get('mr_unauth_session')) {
            this.session = Storage.get('mr_unauth_session')

            if (this.session && this.session.region && this.session.token) {
                return !!this.session
            }
        }

        return false
    }

    reset() {
        Storage.remove('mr_unauth_session')
        this.session = {}
    }
}

export default new UnauthenticatedAuth()
