<template>
    <VueDatePicker
        ref="datePicker"
        data-cy="mr-datepicker"
        :placeholder="placeholder || currentConfig?.inputConfig?.format || currentConfig?.typing"
        :text-input="currentConfig?.inputConfig"
        :teleport="teleport"
        :format="currentConfig?.inputConfig?.format"
        utc="preserve"
        v-bind="{ ...currentConfig?.props }"
        :min-date="dateLow"
        position="left"
        :week-start="isUs ? 0 : 1"
        :max-date="dateHigh"
        :start-date="dateStart"
        :model-value="value"
        :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }"
        @text-submit="onTextSubmit"
        @text-input="onTextInput"
        @invalid-select="onInvalidSelect"
        @cleared="onChange"
        v-on="{ ...currentConfig?.actions }"
    />
</template>

<script setup>
import { Mask } from 'maska'
import moment from 'moment'
import { useStore } from 'vuex'
import Utils from '@serv/Utils'
import VueDatePicker from '@vuepic/vue-datepicker'
import { computed, nextTick, onMounted, ref } from 'vue'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
    modelValue: {
        type: [String, undefined],
        default: undefined
    },
    dateFormat: {
        type: String,
        default: 'yyyy-mm-dd',
        // 'date' is an option just to have backward compatibility. Means same as 'yyyy-mm-dd'.
        validator: (v) => ['date', 'yyyy-mm-dd', 'yyyy-mm', 'yyyy'].includes(v)
    },
    placeholder: {
        type: String,
        default: ''
    },
    teleport: {
        type: Boolean,
        default: true
    },
    rangeLow: { type: [Number, Date], default: 0 },
    rangeHigh: { type: [Number, Date], default: 0 }
})

// update model value and emit to parent
const datePicker = ref()
const value = ref()

onMounted(() => {
    if (props.modelValue) {
        if (props.dateFormat === 'yyyy-mm') {
            const value = props.modelValue.split('-')
            value.value = {
                month: value[1],
                year: value[0]
            }

            onChange(value.value)
        } else if (props.dateFormat === 'yyyy') {
            value.value = +props.modelValue
        } else {
            value.value = props.modelValue
        }
    }
})

const onChange = (newValue) => {
    isTyping.value = false

    if (newValue) {
        /*
        By default, vue-datepicker works with 'select' and 'cancel' buttons. Buttons removed to be consistent with the existing DatePicker, which is working without control buttons.
        To control model change, we are using @dateUpdate and @updateMonthYear. These events emit values earlier than overwrite the internal modelValue.
        nextTick() is to wait for the next event loop cycle and update the modelValue on the next iteration of the event loop.
         */
        if (props.dateFormat === 'yyyy-mm') {
            datePicker.value.selectDate()
            value.value = newValue
            const valueToEmit = `${value.value.year}-${formatMonth(value.value.month)}`
            if (props.modelValue !== valueToEmit) {
                emits('update:modelValue', valueToEmit)
            }
        } else {
            nextTick().then(() => {
                datePicker.value.selectDate()
                let dateMoment = moment(newValue)

                if (props.dateFormat === 'yyyy') {
                    value.value = String(newValue.year)
                    emits('update:modelValue', value.value)
                } else if (dateMoment.isValid()) {
                    value.value = dateMoment.format(currentConfig.value.format)
                    emits('update:modelValue', value.value)
                }
            })
        }
    } else {
        nextTick().then(() => {
            value.value = undefined

            emits('update:modelValue', value.value)
        })
    }
}

// configuration map for different picker types
const store = useStore()
const user = computed(() => store.getters.user)

const locale = computed(() => {
    if (user.value && ['au', 'gb', 'us'].includes(user.value.countryIso)) {
        return `en-${user.value.countryIso.toUpperCase()}`
    }

    return navigator ? navigator.language : `en-GB`
})

const isUs = computed(() => {
    const event = new Date(Date.UTC(2021, 0, 31)) // 31 Jan 2021
    const dateFormat = event.toLocaleDateString(locale.value)

    return !dateFormat.startsWith('31')
})

const formatMonth = (monthIndex) => {
    return (monthIndex + 1) > 9 ? monthIndex + 1 : `0${monthIndex + 1}`
}

const datePickerConfig = computed(() => {
    return {
        'yyyy-mm-dd': {
            mask: '##/##/####',
            format: Utils.serialisedDateFormat,
            inputConfig: { format: isUs.value ? 'MM/dd/yyyy' : 'dd/MM/yyyy', enterSubmit: true },
            props: {
                enableTimePicker: false
            },
            actions: {
                dateUpdate: onChange
            },
            validation: ({ value, mask, format }) => moment(mask.masked(value), format).toDate()
        },
        'yyyy-mm': {
            typing: 'MM/yyyy',
            mask: '##/####',
            format: 'yyyy-mm',
            inputConfig: { format: 'MM/yyyy', enterSubmit: true },
            props: {
                monthPicker: true
            },
            actions: {
                internalModelChange: (newModalValue, internalModelValue) => {
                    if (
                        !isTyping.value &&
                        internalModelValue.year >= 0 &&
                        internalModelValue.month >= 0 &&
                        (value.value?.year !== internalModelValue.year || value.value?.month !== internalModelValue.month)
                    ) {
                        onChange(internalModelValue)
                    }
                }
            },
            validation: ({ value, mask }) => {
                const dates = mask.masked(value).split('/')

                return moment(`${dates[1]}-${dates[0]}`, 'YYYY-MM', true)
            }
        },
        'yyyy': {
            typing: 'yyyy',
            mask: '####',
            format: 'yyyy',
            inputConfig: { format: 'yyyy', enterSubmit: true },
            props: {
                yearPicker: true
            },
            actions: {
                updateMonthYear: onChange
            },
            validation: ({ value }) => {
                return moment(value, 'YYYY', true)
            }
        }
    }
})

const currentConfig = computed(() => {
    return datePickerConfig.value[props.dateFormat] || datePickerConfig.value['yyyy-mm-dd']
})

// Low High and start dates
const dateLow = computed(() => {
    if (props.rangeLow) {
        if (typeof props.rangeLow == 'number') {
            return new Date(moment()
                .subtract(props.rangeLow, 'years')
                .toISOString())
        }

        if (moment(props.rangeLow).isValid()) {
            return props.rangeLow
        }
    }

    return null
})

const dateHigh = computed(() => {
    if (props.rangeHigh) {
        if (typeof props.rangeHigh == 'number') {
            return new Date(moment()
                .subtract(props.rangeHigh, 'years')
                .toISOString()
            )
        }

        if (moment(props.rangeHigh).isValid()) {
            return props.rangeHigh
        }
    }

    return null
})

const dateStart = computed(() => {
    const now = moment()
    if (dateLow.value && dateHigh.value) {
        if (now.isBetween(dateLow.value, dateHigh.value, undefined, [])) {
            return now.toDate()
        }

        return dateHigh.value
    }

    return now.toDate()
})

// action on type date manually in input
const isTyping = ref(false)

const onTextSubmit = () => {
    onChange(value.value)
}

const onTextInput = (event) => {
    invalidDate.value = false

    const mask = new Mask({ mask: currentConfig.value.mask })

    if (!event.target.value && value.value) {
        onChange(undefined)

        return
    }

    if (mask.completed(event.target.value)) {
        isTyping.value = false
        const selectedDate = currentConfig.value.validation({
            value: event.target.value,
            mask,
            format: currentConfig.value.inputConfig.format.toUpperCase()
        })

        if (moment(selectedDate).isValid()) {
            if (props.dateFormat === 'yyyy-mm') {
                nextTick().then(() => {
                    onChange({ year: selectedDate.year(), month: moment(selectedDate).month() })
                    datePicker.value.closeMenu()
                })
            } else if (props.dateFormat === 'yyyy') {
                onChange({ year: event.target.value })
            } else {
                const oldValue = value.value
                datePicker.value.updateInternalModelValue(selectedDate)

                // check is date fit range
                datePicker.value.selectDate()

                if (!invalidDate.value) {
                    onChange(selectedDate)

                    invalidDate.value = false
                } else {
                    onChange(oldValue)
                }
            }
        }
    } else {
        isTyping.value = true
    }
}

// invalid selection
const invalidDate = ref(false)

const onInvalidSelect = () => {
    invalidDate.value = true
}
</script>
