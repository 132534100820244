<template>
    <Loader class="ui grid" />
</template>

<script>
import Loader from '@comp/MrLoader.vue'
import LoginService from '@serv/LoginService'
import Redirect from '@serv/Redirect'
import User from '@model/User.js'

/**
 * We always log out by navigating to this route.
 *  - remove whoami data
 *  - remove the 401 callback as this will trigger `credentials expired` errors on future logins if the user puts in
 * the wrong credentials.
 */
export default {
    name: 'MrLogout',
    components: { Loader },
    methods: {
        doLogout() {
            LoginService.logout().then(() => {
                if (this.$route.query.role === User.Role.patient && this.$route.query.region === 'gb') {
                    Redirect.replace({ name: 'PortalRegistrationFrontNhs' })
                } else if (this.$route.query.role === User.Role.patient) {
                    Redirect.replace({ name: 'PortalRegistrationFront' })
                } else {
                    Redirect.replacePath('/')
                }
            })
        }
    },
    mounted() {
        this.doLogout()
    },
    updated() {
        this.doLogout()
    }
}
</script>
