<template>
    <div
        class="portal-registration"
        :class="{ 'portal-registration--android': isAndroid }"
    >
        <div class="portal-registration__wrapper">
            <LoginBackground
                web-portal
                full-width-bg
            />

            <PortalRegistrationCardWrapper
                :banner-url="showClinicianAssets && bannerUrl"
                :logo-url="showClinicianAssets && logoUrl"
                scrollable
            >
                <template #content>
                    <div class="portal-registration-card__body">
                        <div
                            v-if="$slots.title"
                            class="portal-registration-card__title"
                        >
                            <slot name="title" />
                        </div>
                        <div
                            v-if="$slots.text"
                            class="portal-registration-card__text"
                        >
                            <slot name="text" />
                        </div>

                        <slot
                            v-if="$slots.form"
                            name="form"
                        />

                        <slot name="footer" />
                    </div>
                </template>
            </PortalRegistrationCardWrapper>
            <NotifyMsg />
        </div>
        <template v-if="isLoading">
            <div class="mr-loader-bg" />
            <loader />
        </template>

        <component
            v-if="!!popupClass"
            :is="popupClass"
            :config="popupConfig"
        />
    </div>
</template>

<script>
import LoginBackground from '@comp/LoginBackground.vue'
import { mapState } from 'vuex'
import NotifyMsg from '@comp/NotifyMsg.vue'
import PopupOneButton from '@comp/popups/PopupOneButton.vue'
import PortalRegistrationCardWrapper from '@comp/PortalRegistrationCardWrapper.vue'
import PortalRegistrationMixin from '@mixins/PortalRegistrationMixin'
import useImage from '@composables/useImage'

export default {
    name: 'PortalRegistrationLayout',
    setup() {
        const { getIconUrl } = useImage()

        return {
            getIconUrl
        }
    },
    mixins: [PortalRegistrationMixin],
    components: {
        PortalRegistrationCardWrapper,
        LoginBackground,
        NotifyMsg,
        PopupOneButton
    },
    props: {
        showClinicianAssets: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            bannerUrl: state => state.portalRegistration.bannerUrl,
            logoUrl: state => state.portalRegistration.logoUrl,
            popupClass: state => state.popup.class,
            popupConfig: state => state.popup.config
        })
    }
}
</script>
