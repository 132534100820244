import _ from 'lodash'
import DataTransformTagDimension from '@model/data/DataTransformTagDimension'
import FilterSet from '@model/data/FilterSet'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import store from '@/store'

import StringHelper from '@serv/StringHelper'

// Create DataTransform from type
const mapDataTransformClassnameToClass = {}
mapDataTransformClassnameToClass['DataTransformTagDimension'] = DataTransformTagDimension

/**
 * Functions for managing the lists.
 */
class DataService {
    /**
     * From an array of objects, return a list of DataTransforms.
     */
    getDataTransformsFromConfig(filterSetKey, objects) {
        const transforms = objects.map(object => {
            const Class = mapDataTransformClassnameToClass[object.transform] || DataTransformTagDimension
            object.filterSetKey = filterSetKey

            return new Class(object)
        })

        return transforms
    }

    /**
     * Given a config object and provided user role, filter in place and move embedded objects based on 'roleFiltered'.
     */
    filterConfigObjectWithRole(object, role) {
        const specialKey = 'roleFiltered'

        // First, check and perform any mutation based on roleFiltered
        const rolesObjects = object[specialKey]
        if (rolesObjects) {
            // Find single child object with 'roles' property matching our role, and move all
            // properties to this level
            const matchingObjects = rolesObjects.filter(roleObject => roleObject?.roles?.includes(role))
            if (matchingObjects.length == 0) {
                // No matching object, continue to remove
            } else {
                if (matchingObjects.length > 1) {
                    Logging.error(`${specialKey} block with duplicate role: ${JSON.stringify(rolesObjects, null, 2)}`)
                }
                // Move all matching object properties to top level
                const matchingObject = matchingObjects[0]
                Object.keys(matchingObject).forEach(key => {
                    if (key != 'roles') {
                        object[key] = _.cloneDeep(matchingObject[key])
                    }
                })
            }
            // Remove _roles object
            delete object[specialKey]
        }

        // Iterate all keys and recurse
        Object.keys(object).forEach(key => {
            const value = object[key]
            if (Array.isArray(value)) {
                object[key] = this.filterConfigArrayWithRole(value, role)
            } else if (value instanceof Object) {
                this.filterConfigObjectWithRole(value, role)
            }
        })

        return object
    }

    /**
     * Given a config array and provided user role, filter in place and move embedded objects based on 'roleFiltered'.
     */
    filterConfigArrayWithRole(array, role) {
        // Iterate all array items and recurse
        array.forEach(item => {
            if (Array.isArray(item)) {
                this.filterConfigArrayWithRole(item, role)
            } else if (item instanceof Object) {
                this.filterConfigObjectWithRole(item, role)
            }
        })
        // Remove empty objects, or those that specify roleFilteredRoles not containing ours
        array = array.filter(entry => {
            if (entry instanceof Object && _.isEmpty(entry)) {
                return false
            }

            if (
                entry instanceof Object &&
                Array.isArray(entry.roleFilteredRoles) &&
                !entry.roleFilteredRoles.includes(role)
            ) {
                return false
            }

            return true
        })

        return array
    }

    /**
     * For each object in the list, lookup objects that may have tags (e.g. Provider) and copy any tag values to the object.
     * We do NOT copy a tag value if it already exists on the object.
     */
    addTagsToObjects(objects) {
        objects.forEach(row => {
            const providerSlugs = row.providerSlugs || []
            if (row.providerSlug) {
                providerSlugs.push(row.providerSlug)
            }
            providerSlugs.forEach(providerSlug => {
                const provider = store.state.resources.providers[providerSlug]
                if (provider && provider.tags) {
                    Object.keys(provider.tags).forEach(tagKey => {
                        if (row[tagKey] == undefined) {
                            row[tagKey] = provider.tags[tagKey]
                        }
                    })
                }
            })
        })
    }

    /**
     * For each report in the map:
     * For each row, lookup objects that may have tags (e.g. Provider) and copy any tag values to the root of the row.
     * We do NOT copy a tag value if it already exists on the row.
     */
    addTagsToReportsRows(reports) {
        Object.values(reports).forEach(report => {
            if (report.dataset) {
                this.addTagsToObjects(report.dataset)
            }
        })
    }

    createFilterSet(user, filterSetKey) {
        const transforms = this.getDataTransformsFromConfig(
            filterSetKey,
            user.owner.keyValues?.dash?.overviewPage?.dataTransforms || []
        )
        // Create FilterSet with these transforms and empty filter values
        const transformMap = {}
        const filterStringsMap = {}
        transforms.forEach(transform => {
            transformMap[transform.filterKey] = transform
            filterStringsMap[transform.filterKey] = []
        })
        store.commit(
            'addFilterSet',
            new FilterSet({
                key: filterSetKey,
                filterKeyTransforms: transformMap,
                filterKeyStrings: filterStringsMap,
                isLogging: false
            })
        )
        this.filterTransforms = transforms
    }

    /**
     * For a milestone date filter, get the string to display in the 'before' or 'after' options.
     */
    getDateFilterStringForKeyAndMilestoneSlug(keyStem, milestoneSlug) {
        if (milestoneSlug == 'operation') {
            // Special case, use "Pre-op", "Post-op"
            return Locale.getLanguageItem(`${keyStem}Op`)
        }
        const stringKey = keyStem + StringHelper.capitalize(milestoneSlug)
        const stringValue = Locale.getLanguageItemOrUndefined(stringKey)
        // Generic case, use "Before <milestone label>", "After <milestone label>"
        if (stringValue) {
            return stringValue
        }
        const milestoneLabel = Locale.getLanguageItemForModelEnum('milestone', milestoneSlug)

        return Locale.getLanguageItem(keyStem, [milestoneLabel.toLowerCase()])
    }

    /**
     * Given a params object (usually returned from a FilterSet), process into a form suitable for a POST payload to
     * the reports endpoint. This is used by OverviewCptMixin, generally for all charts on the Overview page(s).
     *
     * Input object example:
     * {
     *   jhub-aor: {
     *     dimension: 'jhub-aor'
     *     teams: [Team, Team...]
     *     value: ['rruAldershot']
     *   }
     *   jhub-provider-type: {
     *     dimension: 'jhub-provider-type'
     *     teams: [Team, Team...]
     *     value: ['pcrf']
     *   }
     *   procedureTitles: [
     *     ['Foot/Ankle', undefined]
     *   ]
     *   jhubUnit: {
     *      patientKeyValue: {
     *          key: "jhubUnit"
     *          values: ['unit1', 'unit2']
     *      }
     *    }
     * }
     * Corresponding output:
     * {
     *   procedureTitles: [
     *     ['Foot/Ankle', undefined]
     *   ]
     *   teams: [gb-123, gb-234...]
     *   patientKeyValue: {
     *       key: "jhubUnit",
     *       values: ['unit1', 'unit2']
     *   }
     * }
     */
    transformOverviewFilterParams(paramsObject) {
        // Intersect any 'teams' and promote to top-level key
        let allTeams = undefined
        const keysToDelete = []
        let patientKeyValue = undefined

        Object.keys(paramsObject).forEach(key => {
            const params = paramsObject[key]
            if (params.teams) {
                allTeams = allTeams ? _.intersectionBy(allTeams, params.teams, 'id') : params.teams
                keysToDelete.push(key)
            }
            if (params.patientKeyValue) {
                patientKeyValue = _.cloneDeep(params)
                keysToDelete.push(key)
            }
        })
        if (allTeams) {
            paramsObject.teams = allTeams.map(team => team.teamUid)
            keysToDelete.forEach(key => delete paramsObject[key])
        }
        if (patientKeyValue) {
            paramsObject = { ...paramsObject, ...patientKeyValue }
            keysToDelete.forEach(key => delete paramsObject[key])
        }

        return paramsObject
    }

    /**
     * Set null dates to be always at the bottom of the table
     */
    reverseRowsByDate(rows, column) {
        const columnIndex = column.columnIndex
        const rowsNull = rows.filter(row => row.listColumns[columnIndex].moment == undefined)
        const rowsNonNull = rows.filter(row => row.listColumns[columnIndex].moment != undefined)
        rows.length = 0
        rows.push(...rowsNonNull.reverse())
        rows.push(...rowsNull)

        return rows
    }
}

export default new DataService()
