<template>
    <div
        v-if="!isCompletedWithoutScore"
        class="survey-step-info-outro-header d-flex justify-center align-center pa-2"
        data-cy="survey-step-info-outro-header"
    >
        <p v-if="isCompletedWithError">
            {{ getLangItem('patientSurveyOutroCannotCalculate') }}
        </p>

        <div
            v-if="isCompletedWithScore"
            class="survey-step-info-outro-header__score"
        >
            <template v-if="viewModel.overallScore">
                <span class="survey-step-info-outro-header__overall-score">
                    {{ getLangItem('patientSurveyOverallScoreTitle') }}
                </span>
                <div class="survey-step-info-outro-header__overall-score">
                    <span>{{ viewModel.overallScore.score }}</span>
                    <span class="survey-step-info-outro-header__overall-slash mx-1">/</span>
                    <span class="survey-step-info-outro-header__overall-max">
                        {{ viewModel.overallScore.maxScore }}
                    </span>
                </div>
            </template>

            <div
                v-if="viewModel.otherScores.length"
                class="mt-2"
            >
                <div
                    v-for="(scoreSection, index) in viewModel.otherScores"
                    :key="index"
                    class="survey-step-info-outro-header__overall-score"
                >
                    <span>{{ `${scoreSection.name}: ` }}</span>
                    <span>{{ scoreSection.score }}</span>
                    <span class="survey-step-info-outro-header__overall-slash">/</span>
                    <span class="survey-step-info-outro-header__overall-max">
                        {{ scoreSection.maxScore }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    viewModel: {
        type: Object,
        required: true
    }
})


const isCompletedWithoutScore = computed(() => props.viewModel.mode === 'displayNoScoresOk')
const isCompletedWithError = computed(() => props.viewModel.mode === 'displayNoScoresError')
const isCompletedWithScore = computed(() => props.viewModel.mode === 'displayScores')
</script>
