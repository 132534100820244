import * as Sentry from '@sentry/vue'
import { auth0 } from '@/plugins/auth0'
import Config from '@serv/Config'
import { createPinia } from 'pinia'
import createRouter from '@src/router'
import LoadScript from 'vue-plugin-load-script'
import mixpanel from './mixpanel.js'
import store from '@src/store'
import SuiVue from 'semantic-ui-vue'
import vuetify from './vuetify'

export function registerPlugins(app) {
    const router = createRouter(app)

    const pinia = createPinia()

    app.use(vuetify).use(LoadScript).use(SuiVue).use(store)

    if (import.meta.env.VITE_SENTRY != 'off') {
        Sentry.init({
            app,
            dsn: 'https://23ca995b381b4aaea598514780858349@o125350.ingest.sentry.io/287515',
            environment: Config.environment,
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router)
                })
            ],
            tracesSampleRate: 0.5,
            denyUrls: [/dev\./, /demo\./, /myrecoverytest\.com/],
            allowUrls: [/myrecovery\.ai/, /msk\.ai/],
            beforeSend(event) {
                if (event.exception?.values) {
                    const exception = event.exception.values[0]

                    if (exception.stacktrace?.frames) {
                        const frames = exception.stacktrace.frames
                        if (frames[frames.length - 1]?.filename?.includes('Logging.js')) {
                            frames.pop()
                        }
                    }
                }

                return event
            }
        })
    }

    if (window.location.pathname.includes('auth')) {
        app.use(auth0)
    }

    app.use(router)
    app.use(pinia)

    app.provide('$mp', import.meta.env.VITE_MIXPANEL === 'on' ? mixpanel : null)
}
