<template>
    <div class="portal-registration">
        <div class="portal-registration__wrapper">
            <LoginBackground
                web-portal
                full-width-bg
            />

            <PortalRegistrationCardWrapper small>
                <template #content>
                    <div
                        v-if="isNhs"
                        class="portal-registration-card__image"
                    >
                        <img
                            :src="getImageUrl('logo-nhs.png')"
                            alt="NHS logo"
                            class="image__support"
                        />
                    </div>

                    <div class="portal-registration-card__body">
                        <div class="portal-registration-card__title">
                            {{ getLangItem(isNhs ? 'patientLoginOptionsTitleNhs' : 'patientLoginOptionsTitle') }}
                        </div>
                        <div class="portal-registration-card__content">
                            <button
                                type="button"
                                class="mr-text-btn"
                                data-cy="login-by-id"
                                @click="onAuthenticate"
                            >
                                <i class="mr-text-btn__icon-next" />
                                {{ getLangItem(isNhs ? 'patientLoginNhsNumber' : 'patientLoginIdNumber') }}
                            </button>
                            <button
                                type="button"
                                class="mr-text-btn"
                                data-cy="login-by-email-phone"
                                @click="onLogin"
                            >
                                <i class="mr-text-btn__icon-next" />
                                {{ getLangItem('patientLoginEmailOrPhone') }}
                            </button>
                        </div>
                    </div>
                </template>
            </PortalRegistrationCardWrapper>
        </div>
        <NotifyMsg />
        <component
            v-if="!!popupClass"
            :is="popupClass"
            :config="popupConfig"
        />
    </div>
</template>

<script>
import LegacyBrowserMixin from '@mixins/LegacyBrowserMixin'
import LoginBackground from '@comp/LoginBackground.vue'
import NotifyMsg from '@comp/NotifyMsg.vue'
import PortalRegistrationCardWrapper from '@comp/PortalRegistrationCardWrapper.vue'
import Redirect from '@serv/Redirect'
import useImage from '@composables/useImage'

export default {
    name: 'PortalRegistrationFront',
    setup() {
        const { getIconUrl, getImageUrl } = useImage()

        return {
            getIconUrl,
            getImageUrl
        }
    },
    mixins: [LegacyBrowserMixin],
    components: {
        PortalRegistrationCardWrapper,
        LoginBackground,
        NotifyMsg
    },
    props: {
        isNhs: { type: Boolean, default: false }
    },
    methods: {
        onAuthenticate() {
            Redirect.goTo({ name: 'PortalRegistrationHospitalNumber', query: this.$route.query })
        },
        onLogin() {
            Redirect.goTo({ name: 'PortalRegistrationLogin', query: this.$route.query })
        }
    },
    created() {
        // Persist flags for other components
        this.$store.commit('setIsStartingAtFrontPage', true)
        this.$store.commit('setIsNhs', this.isNhs)
    }
}
</script>
