export function humanizeAttrVal(value: unknown): string {
    if (value === null || value === undefined || value === '') {
        return '-'
    }

    // this is more for non-technical users, so we want to show 'No' instead of 'false' etc.
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No'
    }

    if (typeof value === 'object') {
        return JSON.stringify(value)
    }

    return value as string
}

export function removeSpacesFromString(value: string): string {
    return value.replace(/\s/g, '')
}
