import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment/moment'
import Patient from '@model/Patient'
import PatientService from '@serv/PatientService'
import store from '@/store'
import Utils from '@serv/Utils'

/**
 * All columns of type carePeriod...
 */
export function _resolveObjectListColumnOfType_bundleEndDate(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.bundleEndDate can only be used on objects of type: Patient`)

        return
    }
    const dischargeMilestone = row.getMilestoneOfSlug('discharge')
    if (dischargeMilestone) {
        const scheduleMoments = PatientService.getPatientScheduleStartEndMoments(row, '90d-post-dis')
        const endMoment = scheduleMoments[1]
        if (endMoment) {
            column.moment = endMoment
            column.cellText = endMoment.format(Utils.readableDateFormat)
            const nowMoment = new moment().startOf('day')
            column.cellSubtitle = Utils.getMomentDiffSummary(nowMoment, scheduleMoments[1])
        }
    }
}

export function _resolvePatientJourneyListColumnOfType_bundleEndDate(column, patientJourney, row, errors, isGoddard) {
    if (!isGoddard) {
        const date = row.bundleEndDate
        if (date) {
            const dateMoment = moment(date)
            column.cellText = dateMoment.format(Utils.readableDateFormat)
            const nowMoment = new moment().startOf('day')
            column.cellSubtitle = Utils.getMomentDiffSummary(nowMoment, dateMoment)
        }
    }

    if (row[column.id]?.date) {
        column.cellText = moment(row[column.id].date).format(Utils.readableDateFormat)
    }
}

export function _resolveObjectListColumnOfType_carePeriodFacility(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.carePeriodFacility can only be used on objects of type: Patient`)

        return
    }
    const providers = store.state.resources.providers
    const carePeriodMilestone = PatientService.getPatientCurrentCarePeriodMilestone(row)
    const provider = carePeriodMilestone ? providers[carePeriodMilestone.providerSlug] : undefined
    column.cellText = provider ? provider.title : ''
}

export function _resolvePatientJourneyListColumnOfType_carePeriodFacility(
    column,
    patientJourney,
    row,
    errors,
    isGoddard
) {
    if (!isGoddard) {
        const providerSlug = row.carePeriodProviderSlug
        if (!providerSlug) {
            return
        }
        const provider = store.state.resources.providers[providerSlug]
        column.cellText = provider ? provider.title : ''
    }

    if (row[column.id]?.title) {
        column.cellText = row[column.id].title
    }
}

export function _resolveObjectListColumnOfType_carePeriodPac(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.carePeriodPac can only be used on objects of type: Patient`)

        return
    }
    let explicitValue
    const nowDate = new moment().format(Utils.serialisedDateFormat)
    const carePeriodMilestone = PatientService.getPatientCurrentCarePeriodMilestone(row)

    if (carePeriodMilestone) {
        explicitValue = carePeriodMilestone.plannedEndDate < nowDate ? 'red' : 'green'
    }

    const scheduleMoments = PatientService.getPatientScheduleStartEndMoments(row, '90d-post-dis')
    const endDate = scheduleMoments[1] && scheduleMoments[1].format(Utils.serialisedDateFormat)
    if (nowDate > endDate) {
        explicitValue = 'grey'
    }
    column.setExplicitValueConfig(explicitValue)
}

export function _resolvePatientJourneyListColumnOfType_carePeriodPac(column, patientJourney, row, errors, isGoddard) {
    if (!isGoddard) {
        column.setExplicitValueConfig(row.carePeriodPac)

        return
    }

    if (row[column.id]) {
        column.setExplicitValueConfig(row[column.id].value)
    }
}

export function _resolveObjectListColumnOfType_carePeriodPlannedEndDate(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.carePeriodPlannedEndDate can only be used on objects of type: Patient`)

        return
    }
    const carePeriodMilestone = PatientService.getPatientCurrentCarePeriodMilestone(row)
    if (carePeriodMilestone) {
        column.moment = moment(carePeriodMilestone.plannedEndDate)
        column.cellText = moment(carePeriodMilestone.plannedEndDate).format(Utils.readableDateFormat)
    }
}

export function _resolvePatientJourneyListColumnOfType_carePeriodPlannedEndDate(
    column,
    patientJourney,
    row,
    errors,
    isGoddard
) {
    if (isGoddard) {
        if (!row[column.id]) {
            return
        }

        if (row[column.id].date) {
            column.cellText = moment(row[column.id].date).format(Utils.readableDateFormat)

            return
        }

        return
    }

    const date = row.carePeriodPlannedEndDate
    if (date) {
        column.cellText = moment(date).format(Utils.readableDateFormat)
    }
}

export function _resolveObjectListColumnOfType_carePeriodStartDate(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.carePeriodStartDate can only be used on objects of type: Patient`)

        return
    }
    const carePeriodMilestone = PatientService.getPatientCurrentCarePeriodMilestone(row)
    if (carePeriodMilestone && carePeriodMilestone.moment) {
        column.moment = carePeriodMilestone.moment
        column.cellText = carePeriodMilestone.moment.format(Utils.readableDateFormat)
    }
}

export function _resolvePatientJourneyListColumnOfType_carePeriodStartDate(
    column,
    patientJourney,
    row,
    errors,
    isGoddard
) {
    if (!isGoddard) {
        const date = row.carePeriodStartDate
        if (date) {
            column.cellText = moment(date).format(Utils.readableDateFormat)
        }

        return
    }

    if (row[column.id]?.date) {
        column.cellText = moment(row[column.id].date).format(Utils.readableDateFormat)
    }
}

export function _resolveObjectListColumnOfType_carePeriodStatus(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.carePeriodStatus can only be used on objects of type: Patient`)

        return
    }
    const nowDate = new moment().format(Utils.serialisedDateFormat)
    const carePeriodMilestone = PatientService.getPatientCurrentCarePeriodMilestone(row)
    if (carePeriodMilestone) {
        column.cellText = Locale.getLanguageItemForModelEnum('periodType', carePeriodMilestone.periodType)
    }
    const scheduleMoments = PatientService.getPatientScheduleStartEndMoments(row, '90d-post-dis')
    const endDate = scheduleMoments[1] && scheduleMoments[1].format(Utils.serialisedDateFormat)
    if (nowDate > endDate) {
        column.cellText = Locale.getLanguageItem('patientListBundleEnded')
    }
}

export function _resolvePatientJourneyListColumnOfType_carePeriodStatus(
    column,
    patientJourney,
    row,
    errors,
    isGoddard
) {
    if (isGoddard) {
        if (!row[column.id]) {
            column.cellText = ''

            return
        }

        if (row[column.id].endDate) {
            column.cellText = Locale.getLanguageItem('patientListBundleEnded')

            return
        }

        column.cellText = Locale.getLanguageItemForModelEnumOrUndefined('periodType', row[column.id].type) || ''

        return
    }

    const nowDate = new moment().format(Utils.serialisedDateFormat)
    const endDate = row.carePeriodEndDate
    const periodType = row.carePeriodType
    if (endDate && nowDate > endDate) {
        column.cellText = Locale.getLanguageItem('patientListBundleEnded')
    } else if (periodType) {
        column.cellText = Locale.getLanguageItemForModelEnum('periodType', periodType)
    }
}
