import _ from 'lodash'
import Config from '@serv/Config'
import Locale from '@serv/Locale'
import moment from 'moment'
import store from '@/store'

import Utils from '@serv/Utils'

/**
 * User object.
 */
const userStandardFields = [
    // These are set when parsing the Patient List response
    'firstName',
    'lastName',
    'owner',
    'persona',
    'personaId',
    'role',
    'title',
    'userId',
    'username',
    // These are set when parsing the Patient response
    'avatarUrl',
    'capabilities',
    'dateOfDeath',
    'isDeceased',
    'dob',
    'email',
    'hasCompletedDashTour',
    'keyValues',
    'dashConfigKeyValues',
    'inTeam', // is this used?
    'isAnon',
    'isChatPrevented',
    'isRegistered',
    'isStaff',
    'isTest',
    'locale',
    'region',
    'tags',
    'temporaryPassword',
    'unauthenticatedToken',
    'unsubscribed'
]

class User {
    constructor(object) {
        // NOTE: We assume store.state.user.owner has already been set
        this.setUserFields(object)
        this.keyValues = this.keyValues || {}
        this.dashConfigKeyValues = this.dashConfigKeyValues || {}
        this.capabilities = this.capabilities || []

        this.sendbirdUserIds = object.sendbirdUserIds || []
        this.sendbirdApplicationId = object.sendbirdApplicationId
    }
    setUserFields(object) {
        userStandardFields.forEach(field => {
            if (object[field] !== undefined) {
                this[field] = object[field]
            }
        })
        this.phone = object.phone || object.mobile // 10/08/22 Renamed 'mobile' field to 'phone'

        // If username not explicitly set, we set here
        this.username = this.username || this.email || this.phone

        // TODO - what to do, now we have country as a tag dimension?
        this.countryIso = this.countryIso || object.countryIso
        this.countryIso = this.countryIso ? this.countryIso.toLowerCase() : undefined
        if (this.countryIso) {
            this.country = this.countryIso.toUpperCase() // required for standard TagDimension filter!
        }

        this.tags = {}
        for (const tag of object.tags || []) {
            this.tags[tag.dimension] = this.tags[tag.dimension] || []
            this.tags[tag.dimension].push(tag.value)
        }
        // Are we a mock user?
        // Note that the dash can instantiate User-derived objects where store.state.user.owner is undefined
        const mockUsers = ((((store.state.user || {}).owner || {}).keyValues || {}).dash || {}).mockUsers || []
        this.isMock = mockUsers.includes(this.email)
    }

    // 10/08/22 Renamed 'mobile' field to 'phone'
    get mobile() {
        return this.phone
    }

    /**
     * Get the user full name, e.g. 'Bob Smith'.
     */
    get fullName() {
        let parts = []
        if (this.firstName != undefined) {
            parts.push(this.firstName)
        }
        if (this.lastName != undefined) {
            parts.push(this.lastName)
        }
        if (this.isDeceased || !!this.dateOfDeath) {
            parts.push(Locale.getLanguageItem('patientPageDeceasedTitleSuffix'))
        }
        let fullName = parts.join(' ')

        return fullName
    }

    static getUserByTitledFullName(name) {
        return Object.values(store.state.user.users).find(user => user.titledFullName == name)
    }

    static getUserByTitledFullNameWithRole(name) {
        return Object.values(store.state.user.users).find(user => user.titledFullNameWithRole == name)
    }

    /**
     * Get the initials of the user e.g. 'BS' for Bob Smith
     */
    getInitials() {
        const letters = []
        if (this.firstName) {
            letters.push(this.firstName[0])
        }
        if (this.lastName) {
            letters.push(this.lastName[0])
        }

        return letters.join('').toUpperCase()
    }

    /**
     * Get the user title with the specified name.
     */
    titledName(name) {
        let parts = []
        if (!_.isEmpty(this.title)) {
            parts.push(this.title)
        }
        parts.push(name)

        return parts.join(' ')
    }

    /**
     * Get the user titled last name, e.g. 'Dr Smith'.
     */
    get titledLastName() {
        return this.titledName(this.lastName)
    }

    /**
     * Get the user titled full name, e.g. 'Dr Bob Smith'.
     */
    get titledFullName() {
        return this.titledName(this.fullName)
    }

    /**
     * Get the user titled full name prefixed with their bracketed role, e.g. '(Surgeon) Dr Bob Smith'.
     */
    get titledFullNameWithRole() {
        return `(${this.personaLabel}) ${this.titledFullName}`
    }

    /**
     * Get the role if defined, else the persona.
     */
    get roleOrPersona() {
        return this.role || this.persona
    }

    /**
     * Get the user role or user persona label, e.g. 'Clinician', 'Surgeon'.
     */
    get personaLabel() {
        const roleStringId = Locale.getStringIdForModelEnum('role', this.role || '')
        let label = Locale.getLanguageItemOrUndefined(roleStringId)
        if (label == undefined) {
            label = Locale.getLanguageItemForModelEnum('persona', this.persona)
        }

        return label
    }

    /**
     * Return true if we should show the 'tour' for this user.
     */
    get shouldShowTour() {
        return Config.debugEnabled || Config.spoof ? false : !this.hasCompletedDashTour
    }

    // Role getters based on persona property
    get isPatient() {
        return this.persona == User.Persona.patient
    }
    get isClinician() {
        return this.persona == User.Persona.clinician
    }
    get isProducerExec() {
        return this.persona == User.Persona.producerExec
    }
    get isProviderExec() {
        return this.persona == User.Persona.providerExec
    }
    get isExec() {
        return this.isProducerExec || this.isProviderExec
    }
    // Role getters based on role property
    get isSurgeon() {
        return this.role == User.Role.surgeon
    }
    get isAdminAssistant() {
        return this.role == User.Role.adminAssistant
    }
    get isHcp() {
        return this.role == User.Role.hcp
    }
    get isHcpCareNavigator() {
        return this.role == User.Role.hcpCareNavigator
    }
    get isHcpRtm() {
        return this.role == User.Role.hcpRtm
    }
    get isOutcomesExec() {
        return this.role == User.Role.outcomesExec
    }
    get isAdmin() {
        return this.persona == User.Persona.admin
    }

    get isHcpPt() {
        return this.role == User.Role.hcpPt
    }

    // Return the user age in years
    get age() {
        return this.dob ? moment().diff(this.dob, 'years') : undefined
    }

    // Return the user DOB, e.g. '02 Jun 1971'
    get readableDob() {
        return this.dob ? moment(this.dob).format(Utils.readableDateFormat) : undefined
    }

    // Query capability, e.g. this.has(User.Capability.canReferPatients)
    has(capability) {
        return this.capabilities.includes(capability)
    }
}

User.constructWithDefaults = false

/**
 * User personas.
 */
User.Persona = {
    admin: 'admin',
    clinician: 'clinician',
    patient: 'patient',
    producerExec: 'producerExec',
    providerExec: 'providerExec'
}

/**
 * User roles.
 */
User.Role = {
    adminAssistant: 'adminAssistant', // NOTE: this is NOT one of our internal admin roles!
    adminContent: 'adminContent',
    adminDeveloper: 'adminDeveloper',
    adminRtm: 'adminRtm',
    adminSupport: 'adminSupport',
    hcp: 'hcp',
    hcpCareNavigator: 'hcpCareNavigator',
    hcpPt: 'hcpPt',
    hcpRtm: 'hcpRtm',
    outcomesExec: 'outcomesExec',
    patient: 'patient',
    providerExec: 'providerExec',
    surgeon: 'surgeon'
}

/**
 * User capabilities.
 */
User.Capability = {
    canAddPatient: 'canAddPatient',
    canBeReferredTo: 'canBeReferredTo',
    canChangePassword: 'canChangePassword',
    canChat: 'canChat',
    canDischargePatient: 'canDischargePatient',
    canEditExerciseTemplates: 'canEditExerciseTemplates',
    canJoinTeam: 'canJoinTeam',
    canLeadTeam: 'canLeadTeam',
    canReferPatient: 'canReferPatient',
    canReviewMonths: 'canReviewMonths',
    canReviewRtm: 'canReviewRtm',
    canReviewSurveys: 'canReviewSurveys',
    canViewAdmin: 'canViewAdmin',
    canViewAllTeamPatients: 'canViewAllTeamPatients',
    canViewPatientData: 'canViewPatientData'
}

export default User
