import BaseContent from '@model/content/BaseContent'
import Logging from '@serv/Logging.js'

class Media extends BaseContent {
    constructor(object) {
        super(object)
        this.uuid = object.src.$blob
        this.mimeType = object.src.mimeType
    }

    get blobUrlFull() {
        const staticContentUrl = import.meta.env.VITE_STATIC_CONTENT_URL || ''

        if (!staticContentUrl) {
            Logging.error('Static content CDN url not set')

            return ''
        }

        return staticContentUrl.replace('{uuid}', this.uuid)
    }
}

export default Media
