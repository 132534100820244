import ListTransform from '@model/list/ListTransform'
import StringHelper from '@serv/StringHelper'

class ListTransformGrmaStocPreOpEducationAppt extends ListTransform {
    constructor(object) {
        object.filterKey = object.column.key
        super(object)
        this.sortKey = this.column.key
    }

    get getFilterConfig() {
        return this.column.getExplicitValueFilterConfig()
    }

    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        if (this.column.valueFilterOptions) {
            const values = keyStrings.map(key => {
                const option = this.column.valueFilterOptions[key]

                return Array.isArray(option) ? option[0] : option
            })

            return isGoddard ? { values } : values.join(',')
        }

        return isGoddard ? { values: keyStrings } : keyStrings.join(',')
    }

    getSortParameter() {
        return StringHelper.camelCaseToSnakeCase(this.column.key)
    }
}

export default ListTransformGrmaStocPreOpEducationAppt
