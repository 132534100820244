import _ from 'lodash'
import Analytics from '@serv/Analytics'
import ConfigManager from '@config/ConfigManager'
import InfoStep from '@model/content/InfoStep'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment'
import NotifyService from '@serv/NotifyService'
import ScoreSection from '@model/ScoreSection'
import store from '@/store'
import SurveyService from '@serv/SurveyService'

import SurveyResult from '@model/SurveyResult'
import TaskUi from '@serv/TaskUi'
import Utils from '@serv/Utils'

let singleton = undefined

/**
 * Instantiated for each instance of a web survey. Manages user progress through the survey
 * and associated SurveyResult.
 */
class TaskHandler {
    constructor(survey, surveyResult, patient, patientJourney, isLoggingVerbose, scheduleEvent) {
        singleton = this
        this.survey = survey
        this.surveyResult = surveyResult

        this.scheduleEvent = scheduleEvent

        // Required for JS interface functions such as getNumDaysPostOp
        this.patient = patient
        this.patientJourney = patientJourney

        // Start at the beginning
        this.stepIndex = 0
        this.postponeNextStep = false
        this.skippedStepSlugs = new Set()
        this.allowPartial = false

        this.lastStepIndex = this.survey.steps.length - 1
        this.isLoggingVerbose = !!isLoggingVerbose

        // Keep copy of current StepResult.results. Use these to see if current StepResult has been modified
        this.stepResultValuesCopy = undefined

        // This is updated by a function that's called from the step components
        this.nextButtonStatus = TaskHandler.NextButtonStatus.disabled
        this.nextButtonLabel = ''

        // Temporarily disable all input, if auto-skipping between steps
        this.isSkipping = false

        TaskUi.get().reset()
        this.startOrResume()
    }

    //-------------------------------------------------------------------------
    // Private functions, used only within this file
    //-------------------------------------------------------------------------
    get isAutoNext() {
        return this.survey.containsTag('auto-next')
    }

    get hasBranching() {
        return this.survey.containsTag('has-branching')
    }

    // Is this step skippable? True if step OR survey tagged as allow-skip.
    get isSkippable() {
        return this.survey.containsTag('allow-skip') || this.currentStep.isSkippable
    }

    // Get the number of optionally skippable steps that were skipped by the user.
    get numSkippedSteps() {
        return this.skippedStepSlugs.size
    }

    // Get an array of step slugs that will always be skipped.
    get alwaysSkippedSteps() {
        const fnName = 'taskGetAlwaysSkippedSteps'
        const stepSlugs = this.callFunction(fnName)

        return stepSlugs || []
    }

    // Get the callback function name, e.g. taskEnterStep_ases_q1_surv
    static getJsFnName(stem, stepSlug) {
        // NOTE We need to use a regex to get replaceAll behaviour
        const name = `${stem}_${stepSlug.replace(/-/g, '_')}`

        return name
    }

    // Keep copy of current StepResult.results. Use these to see if current StepResult has been modified.
    copyCurrentStepResultResults() {
        if (this.currentStepResult) {
            this.stepResultValuesCopy = {
                value: this.currentStepResult.value,
                freeTextValue: this.currentStepResult.freeTextValue
            }
        }
    }

    // Call the named function, assumed to be in the global namespace.
    // NOTE: Does nothing in mocha tests
    callFunction(name, value) {
        const globals = Utils.isInTest ? global : window
        if (globals && globals[name]) {
            return globals[name](value)
        }
    }

    /**
     * Begin the task from the beginning, or from where we left off, as follows:
     * - Set our stepIndex to the index of the first step with a nil result value (of 0 if not found)
     * - For this step, set the startTime to now
     * - If this is step 0, also set the Task startTime to now
     * Note that if activity is not marked as allowPartial, we ALWAYS start from step 0.
     */
    startOrResume() {
        // Do we have any existing result values on this result?
        const nowMoment = new moment()
        const nowTime = nowMoment.format(Utils.serialisedDateTimeFormat)

        if (this.surveyResult.hasAnyResults()) {
            const firstEmptyIndex = this.surveyResult.getFirstNonCompleteStepIndex(this.survey)
            if (firstEmptyIndex != undefined && firstEmptyIndex != this.lastStepIndex) {
                // We are resuming. startTime/endTime will already be set
                this.stepIndex = firstEmptyIndex
            } else {
                // We've actually completed all the steps that require values
                // Skip to outro step
                this.stepIndex = this.lastStepIndex
                this.callFunction('taskComplete')
            }
        } else {
            // We are starting - either at step 0, or step 1 (skipping intro page we've already viewed)
            this.stepIndex = 0
            // if (this.survey.isFirstStepIntroJourney() && !this.survey.shouldShowIntro(model: this.model) {
            //     this.stepIndex = 1
            // }
            this.surveyResult.setStartTime(nowTime)
            this.surveyResult.setEndTime(nowTime)
        }
        this.surveyResult.setStartTimeForStepAtIndex(this.stepIndex, nowTime)
        this.surveyResult.status = SurveyResult.Status.partial
        this.copyCurrentStepResultResults()
        this.calcNextButtonStatus()

        // JavaScript bridge
        this.postponeNextStep = false
        if (this.getNumSteps() > 0) {
            this.callFunction('taskEnter')
            this.callFunction('taskEnterStep')
            const stepSlug = this.getCurrStepSlug()
            const jsFnName = TaskHandler.getJsFnName('taskEnterStep', stepSlug)
            this.callFunction(jsFnName)
        }
        if (this.isLoggingVerbose) {
            Logging.log(`TaskHandler new stepIndex: ${this.stepIndex}`)
        }
    }

    // Exit one step and enter another, calling the correct callback functions.
    jsExitAndEnterStep(fromIndex, toIndex, movingBack) {
        const currIndex = this.stepIndex

        const oldStepResult = this.surveyResult.stepResults[fromIndex]
        const currentStepResultValues = {
            value: oldStepResult.value,
            freeTextValue: oldStepResult.freeTextValue
        }
        if (!_.isEqual(currentStepResultValues, this.stepResultValuesCopy)) {
            // We've changed the StepResult.
            // Ideally this callback would be specified as a TaskHandler property - but we wanted it to be a
            // SurveyService function
            SurveyService.onStepResultChanged(this)
        }

        this.stepIndex = fromIndex
        let step = this.survey.steps[this.stepIndex]
        if (!movingBack) {
            const jsFnName = TaskHandler.getJsFnName('taskExitStep', step.slug)
            this.callFunction(jsFnName)
            this.callFunction('taskExitStep')
        }

        // Did the JavaScript elect to postpone moving to next step? If so, bail
        if (this.postponeNextStep) {
            return
        }

        // Has the JS function changed the stepIndex? If so, leave it as-is...
        if (this.stepIndex == fromIndex) {
            this.stepIndex = toIndex
        }

        // If the new step slug contains '-outro', call the JavaScript taskComplete() function
        // This will also calculate any Score array.
        step = this.survey.steps[this.stepIndex]
        if (step.slug.includes('-outro')) {
            this.callFunction('taskComplete')
        }
        this.callFunction('taskEnterStep')

        // Has the JS function changed the stepIndex? If so, do NOT call the step-specific Enter function
        if (currIndex == this.stepIndex) {
            const jsFnName = TaskHandler.getJsFnName('taskEnterStep', step.slug)
            this.callFunction(jsFnName)
        }
        this.copyCurrentStepResultResults()
    }

    savePartialResult() {
        const time = moment().format(Utils.serialisedDateTimeFormat)
        this.surveyResult.setEndTime(time)
        this.surveyResult.setEndTimeForStepAtIndex(this.stepIndex, time)
        this.surveyResult.status = SurveyResult.Status.partial
    }

    exit() {
        this.savePartialResult()

        // JavaScript bridge
        const stepSlug = this.getCurrStepSlug()
        const jsFnName = TaskHandler.getJsFnName('taskExitStep', stepSlug)
        this.callFunction(jsFnName)
        this.callFunction('taskExitStep')
        this.callFunction('taskExit')
    }

    complete() {
        const time = moment().format(Utils.serialisedDateTimeFormat)
        this.surveyResult.setEndTimeForStepAtIndex(this.stepIndex, time)

        // JavaScript bridge
        let step = this.survey.steps[this.stepIndex]
        const jsFnName = TaskHandler.getJsFnName('taskExitStep', step.slug)
        this.callFunction(jsFnName)
        this.callFunction('taskExitStep')

        // If the final step slug contains '-outro', do NOT call the JavaScript taskComplete() function,
        // as we should already have done so...
        step = this.survey.steps[this.survey.steps.length - 1]
        if (!step.slug.includes('-outro')) {
            this.callFunction('taskComplete')
        }

        // Must call this AFTER JavaScript taskComplete, for scoring
        // Sets ActivityResult endTime, writes to disk and potentially network
        this.surveyResult.complete()

        // Global JS callback, after building list
        this.callFunction('postActivityComplete')

        Analytics.logSurveyComplete(this.survey, this.surveyResult)
    }

    // Return true only if the current StepResult is fully completed.
    // This includes checking for any required free-text value.
    get isCurrentStepResultFullyComplete() {
        return this.currentStepResult.isComplete
    }

    //-------------------------------------------------------------------------
    // JavaScript-native interface.
    //-------------------------------------------------------------------------
    static get() {
        return singleton
    }

    get isFirstStep() {
        return this.stepIndex == 0
    }

    get isLastStep() {
        return this.stepIndex == this.survey.steps.length - 1
    }

    // Are we at an "intro step"?
    get isIntroStep() {
        return this.isFirstStep && this.currentStep.slug.includes('-intro')
    }

    // Are we at an "outro step"?
    get isOutroStep() {
        return this.isLastStep && this.currentStep.slug.includes('-outro')
    }

    get currentStep() {
        return this.survey.steps[this.stepIndex]
    }

    get currentStepResult() {
        return this.surveyResult.stepResults.find(result => result.stepSlug === this.currentStep.slug)
    }

    // Calculated enabled status of Next button, and label.
    calcNextButtonStatus() {
        let stringKey
        if (this.isLastStep) {
            this.nextButtonStatus = TaskHandler.NextButtonStatus.submit
            stringKey = 'patientSurveyNavSubmit'
        } else if (this.isCurrentStepResultFullyComplete || !this.isSkippable) {
            this.nextButtonStatus = TaskHandler.NextButtonStatus.next
            stringKey = 'patientSurveyNavNext'
        } else {
            this.nextButtonStatus = TaskHandler.NextButtonStatus.skip
            stringKey = 'patientSurveyNavSkip'
        }
        this.nextButtonLabel = Locale.getLanguageItem(stringKey)

        const isNextButtonEnabled =
            this.isSkippable || this.currentStep instanceof InfoStep || this.isCurrentStepResultFullyComplete
        if (!isNextButtonEnabled) {
            this.nextButtonStatus = TaskHandler.NextButtonStatus.disabled
        }
    }

    // Is Previous button enabled?
    get isPrevButtonEnabled() {
        return this.stepIndex > 0
    }

    // Move to the next step, calling correct functions.
    nextStep() {
        if (this.isLoggingVerbose) {
            Logging.log(`TaskHandler StepResult: ${JSON.stringify(this.currentStepResult, null, 2)}`)
        }
        // If any free text value, validate first before allowing next
        if (this.currentStepResult.freeTextValue) {
            this.currentStepResult.freeTextValue = this.currentStepResult.freeTextValue.trim()
            const jsFnName = TaskHandler.getJsFnName('taskValidateStepFreeText', this.currentStep.slug)
            const result = this.callFunction(jsFnName, this.currentStepResult.freeTextValue)
            if (result) {
                // Validation failure
                // Display popup with specified title/text
                // TODO i18n
                store.commit('popup/setConfig', {
                    title: result.title,
                    text: result.text,
                    buttonText: Locale.getLanguageItem('ok')
                })
                store.commit('popup/setClass', 'PopupOneButton')

                // Do NOT move to next step
                return
            }
        }

        // If SurveyResult.id is not set, we have not yet successfully POSTed the SurveyResult to the BE.
        // Display an error notification and abort (user must tap Next again)
        if (this.surveyResult.uuid && !this.surveyResult.id && !ConfigManager.isMockingServer) {
            const text = Locale.getLanguageItem('patientSurveyAwaitResult')
            NotifyService.error(text)
            // Log as warning, but send to Mixpanel as error
            Logging.warn(text)

            return
        }

        // If we're skipping, keep a note
        if (this.nextButtonStatus == TaskHandler.NextButtonStatus.skip) {
            this.skippedStepSlugs.add(this.currentStep.slug)
        } else {
            this.skippedStepSlugs.delete(this.currentStep.slug)
        }

        const time = moment().format(Utils.serialisedDateTimeFormat)
        this.surveyResult.setEndTimeForStepAtIndex(this.stepIndex, time)

        this.stepIndex += 1
        this.postponeNextStep = false
        this.jsExitAndEnterStep(this.stepIndex - 1, this.stepIndex, false)

        if (!this.postponeNextStep) {
            this.surveyResult.setStartTimeForStepAtIndex(this.stepIndex, time)
            Analytics.sendEvent('surveyNext', {
                contentSlug: this.survey.slug,
                stepSlug: this.survey.steps[this.stepIndex].slug,
                prevStepValue: this.surveyResult.stepResults[this.stepIndex - 1].value
            })
        }
        if (this.isLoggingVerbose) {
            Logging.log(`TaskHandler new stepIndex: ${this.stepIndex}`)
        }
        this.calcNextButtonStatus()
    }

    // Find the most recent step which has a result value, passing in the step index to start from.
    // If none found, return 0.

    // Find the most recent step which either:
    // - has a result value
    // - has been skipped (not due to branching)
    findMostRecentStepIndexWithResult(index) {
        const alwaysSkippedSteps = this.alwaysSkippedSteps
        let stepIndex = index
        while (stepIndex > 0) {
            stepIndex -= 1
            const step = this.survey.getStepAt(stepIndex)
            const hasValue = !!this.surveyResult.getValueForStepAtIndex(stepIndex)
            const isAlwaysSkipped = alwaysSkippedSteps.includes(step.slug)
            if (hasValue || (this.isSkippable && !isAlwaysSkipped)) {
                break
            }
        }

        return stepIndex
    }

    // Clears results between firstStepIndex and secondStepIndex, keeps result at secondStepIndex
    clearResultsBetween(firstStepIndex, secondStepIndex) {
        let stepIndex = firstStepIndex
        while (stepIndex != secondStepIndex) {
            this.surveyResult.removeAllResultsForStepResultAtIndex(stepIndex)
            if (stepIndex < secondStepIndex) {
                stepIndex += 1
            } else {
                stepIndex -= 1
            }
        }
    }

    // Move to the previous step, calling correct functions.
    prevStep() {
        if (this.isLoggingVerbose) {
            Logging.log(`TaskHandler StepResult: ${JSON.stringify(this.currentStepResult, null, 2)}`)
        }
        if (!this.isFirstStep) {
            if (this.survey.containsTag('onboarding')) {
                this.stepIndex -= 1
            } else {
                const time = moment().format(Utils.serialisedDateTimeFormat)
                this.surveyResult.setEndTimeForStepAtIndex(this.stepIndex, time)

                const oldStepIndex = this.stepIndex
                this.stepIndex = this.findMostRecentStepIndexWithResult(this.stepIndex)
                this.jsExitAndEnterStep(oldStepIndex, this.stepIndex, true)

                // TODO when we have proper results
                // if (this.hasBranching) {
                //     this.clearResultsBetween(oldStepIndex, this.stepIndex)
                // }
                this.surveyResult.setStartTimeForStepAtIndex(this.stepIndex, time)
            }
        }
        if (this.isLoggingVerbose) {
            Logging.log(`TaskHandler new stepIndex: ${this.stepIndex}`)
        }
        this.calcNextButtonStatus()
        Analytics.sendEvent('surveyPrev', {
            contentSlug: this.survey.slug,
            stepSlug: this.survey.steps[this.stepIndex].slug
        })
    }

    // Set the value of the current step.
    setResultValue(value) {
        this.currentStepResult.setResultValue(value)
    }

    // Go to a named step.
    goToStep(stepSlug) {
        const _this = this
        let stepIndex = -1
        while (++stepIndex < this.survey.totalSteps) {
            const step = this.survey.steps[stepIndex]
            if (step.slug == stepSlug) {
                _this.stepIndex = stepIndex
                const jsFnName = TaskHandler.getJsFnName('taskEnterStep', stepSlug)
                _this.callFunction(jsFnName)
                break
            }
        }
    }

    isCurrStepFirst() {
        return this.isFirstStep
    }

    isCurrStepLast() {
        return this.isLastStep
    }

    setAsLastStep() {
        this.lastStepIndex = this.stepIndex
    }

    getNumSteps() {
        return this.survey.totalSteps
    }

    getCurrStepIndex() {
        return this.stepIndex
    }

    getCurrStepSlug() {
        return this.currentStep.slug
    }

    // Always return only the first value, even if it's an array
    getCurrStepResultValue() {
        const stepResult = this.currentStepResult
        if (typeof stepResult.value == 'string') {
            return stepResult.value
        }

        if (Array.isArray(stepResult.value)) {
            return stepResult.value[0]
        }

        if (stepResult.freeTextValue != undefined) {
            return stepResult.freeTextValue
        }

        return stepResult.value
    }

    // Always return all values, as an array
    getCurrStepResultValues() {
        return this.getStepResultValues(this.currentStepResult)
    }

    getStepResultValues(stepResult) {
        let values = []
        if (typeof stepResult.value == 'string') {
            values = [stepResult.value]
        } else if (Array.isArray(stepResult.value)) {
            values = stepResult.value
        }
        if (stepResult.freeTextValue != undefined) {
            values.push(stepResult.freeTextValue)
        }

        return values
    }

    // Get all step result values, exactly 1 for each step with results, as an array of objects with 'contentSlug' and 'value' members.
    // Each 'value' can be a single value, or an array of values.
    getStepResultValuesArray() {
        const results = []
        this.surveyResult.stepResults.forEach(stepResult => {
            const result = { contentSlug: stepResult.stepSlug }
            const singleValue = stepResult.getSingleValue()
            if (stepResult.value == undefined) {
                // Pass
            } else if (singleValue != undefined) {
                result.value = singleValue
                results.push(result)
            } else {
                result.value = this.getStepResultValues(stepResult)
                results.push(result)
            }
        })

        return results
    }

    getStepResultValue(stepSlug) {
        const stepResult = this.surveyResult.stepSlugToStepResult[stepSlug]
        if (!stepResult) {
            Logging.warn(`Could not get StepResult for step slug ${stepSlug}`)
        }

        return stepResult ? stepResult.value : undefined
    }

    getStepTextResultValue(stepSlug) {
        const stepResult = this.surveyResult.stepSlugToStepResult[stepSlug]
        if (!stepResult) {
            Logging.warn(`Could not get StepResult for step slug ${stepSlug}`)
        }

        return stepResult ? stepResult.freeTextValue : undefined
    }

    // Get the number of days since the op date
    get numDaysPostOp() {
        const opMilestone = this.patientJourney.getMilestoneOfSlug('operation')
        if (opMilestone && opMilestone.moment) {
            const nowMoment = new moment()

            return nowMoment.diff(opMilestone.moment, 'days')
        }

        return 0
    }

    getNumDaysPostOp() {
        return this.numDaysPostOp
    }

    isSchedulePreOp() {
        return this.numDaysPostOp <= 0
    }

    isSchedulePostOp() {
        return this.numDaysPostOp >= 0
    }

    setScoreSectionValue(sectionSlug, value, displayValue) {
        Logging.log(`setScoreSectionValue: ${sectionSlug}, ${value}`)
        this.surveyResult.scoreMap[sectionSlug] = new ScoreSection({
            section: sectionSlug,
            value: value,
            displayValue: displayValue
        })
    }

    getScoreSectionValue(sectionSlug) {
        return (this.surveyResult.scoreMap[sectionSlug] || {}).value
    }

    taskSlug() {
        return this.survey.slug
    }

    /**
     * Get the "view model" required to display the outro header, including the mode and any score section names/values.
     * NOTE:
     * - We assume that any survey supporting skipping, i.e. where it might be possible to reach the outro and not be able to calculate a desired score,
     * explicitly defines keyValues.scoreSections
     */
    getOutroHeaderViewModel() {
        const viewModel = {
            otherScores: []
        }
        const overallSection = this.survey.getOverallScoreSection(true) // any displayable overall score?
        const otherSections = this.survey.getOtherScoreSections(true) // any displayable other scores?

        // Overall score
        const score = this.surveyResult.score
        const noScoreSections =
            this.survey.keyValues.scoreSections == undefined || this.survey.keyValues.scoreSections.length == 0
        let cannotCalculateOverallScore = false
        let attemptOverallScore = false
        if (overallSection || (noScoreSections && (this.survey.isClinicalSurvey || this.survey.isPainSurvey))) {
            // "Score" section explicitly defined, or NO scoreSections defined and we are a clinical or pain survey
            attemptOverallScore = true
            if (!isNaN(score)) {
                const precisionDp = overallSection ? overallSection.precisionDp || 1 : 1
                const maxScore = overallSection ? overallSection.maxScore : this.survey.maxScore
                viewModel.overallScore = {
                    score: Number.isInteger(score) ? score : score.toFixed(precisionDp),
                    maxScore: maxScore
                }
            } else {
                cannotCalculateOverallScore = true
            }
        }

        // Other scores
        if (otherSections.length > 0) {
            viewModel.mode = TaskHandler.OutroHeaderMode.displayScores
            viewModel.otherScores = []
            otherSections.forEach(section => {
                const surveyResultSectionValue = this.surveyResult.getScore(section.name)
                if (surveyResultSectionValue) {
                    viewModel.otherScores.push({
                        name: section.name,
                        score: surveyResultSectionValue,
                        maxScore: section.maxScore
                    })
                }
            })
            if (viewModel.otherScores.length > 0 || viewModel.overallScore) {
                viewModel.mode = TaskHandler.OutroHeaderMode.displayScores
            } else {
                viewModel.mode = TaskHandler.OutroHeaderMode.displayNoScoresError
            }
        } else {
            // No displayable other sections defined
            if (!attemptOverallScore || cannotCalculateOverallScore) {
                if (overallSection) {
                    viewModel.mode = TaskHandler.OutroHeaderMode.displayNoScoresError
                } else {
                    viewModel.mode = TaskHandler.OutroHeaderMode.displayNoScoresOk
                }
            } else {
                viewModel.mode = TaskHandler.OutroHeaderMode.displayScores
            }
        }

        if (viewModel.mode == TaskHandler.OutroHeaderMode.displayNoScoresError) {
            const numSkippedSteps = this.numSkippedSteps
            if (numSkippedSteps == 0) {
                // No skipped steps: this is a bug - log an error and display tick
                viewModel.mode = TaskHandler.OutroHeaderMode.displayNoScoresOk
                Logging.error(
                    `Survey ${this.survey.slug} has keyValues.scoreSections defined for Score, but result has no overall Score value and no skipped steps`
                )
            }
        }

        return viewModel
    }
}

TaskHandler.NextButtonStatus = {
    disabled: 'disabled',
    next: 'next',
    skip: 'skip',
    submit: 'submit'
}

TaskHandler.OutroHeaderMode = {
    // No scores defined, or none to display but no error - display tick
    displayNoScoresOk: 'displayNoScoresOk',
    // Scores defined but cannot calculate any - display message
    displayNoScoresError: 'displayNoScoresError',
    // At least one score calculated - display scores list
    displayScores: 'displayScores'
}

export default TaskHandler
