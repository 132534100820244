/* eslint no-console: "off" */
import * as Sentry from '@sentry/vue'
import { capitalize } from '@comp/Filters.js'
import ConfigManager from '@config/ConfigManager'

/**
 * The Logging service logs messages to the console.
 * Debug messages can be toggled on or off by setting `localStorage.debug`
 * to [log, info, warn, error] or "log, info"
 */
class Logging {
    constructor() {
        const levels = localStorage?.debug

        this.logLevel = levels
            ? Array.isArray(levels)
                ? levels
                : levels.split(',').map(item => item.trim())
            : ['error']

        this.Tags = {
            Fixtures: 'Fixtures',
            Content: 'Content'
        }
    }

    /** @private */
    _log(level = 'log', message) {
        let Levels = ['log', 'info', 'warn', 'error']

        if (!Levels.includes(level)) {
            throw new Error('Invalid logging level:', level)
        }

        let displayLevel = `${level.toUpperCase()} »`

        console[level](displayLevel, ...message)
    }

    /**
     * Log message
     * @param message {String|Array}
     */
    log(...message) {
        if (this.logLevel.includes('log') || ConfigManager.isMockingServer) {
            this._log('log', message)
        }
    }

    /**
     * Log info message
     * @param message {String|Array}
     */
    info(...message) {
        if (this.logLevel.includes('info')) {
            this._log('info', message)
        }
    }

    /**
     * Log warning message
     * @param message {String|Array}
     */
    warn(...message) {
        if (this.logLevel.includes('warn')) {
            this._log('warn', message)
        }
    }

    /**
     * @param {boolean} sendToMixpanel - Deprecated: All errors should be sent only to Sentry
     * */
    // eslint-disable-next-line no-unused-vars
    error(message, sendToMixpanel = false, tags = []) {
        this._log('error', [message])

        if (tags?.length > 0) {
            this.setSentryTags(tags, message)
        }

        Sentry.captureException(new Error(message))
    }

    addSentryBreadcrumbs(messages, category = 'default') {
        if (!Array.isArray(messages)) {
            messages = [messages]
        }

        messages.forEach(message => {
            Sentry.addBreadcrumb({
                category: category,
                message: message,
                level: 'info'
            })
        })
    }

    setSentryTags(tags, message) {
        let messageTagExists = false
        let messageTag = ''
        if (message) {
            messageTag = this.Tags[capitalize(message.split(':')[0])] || ''
        }

        tags.forEach(tag => {
            if (this.Tags[tag.tag]) {
                Sentry.setTag(tag.tag, tag.value)

                if (tag.tag == messageTag) {
                    messageTagExists = true
                }
            }
        })

        if (!messageTagExists && messageTag) {
            Sentry.setTag(messageTag, '')
        }
    }
}

export default new Logging()
