<template>
    <v-slider
        :class="{ 'pb-2': direction === 'vertical' }"
        :step="stepSize"
        flat
        :min="min"
        :max="max"
        hide-details
        :direction="direction"
        color="primary"
        track-color="#79829B"
        :model-value="modelValue"
        @update:model-value="emits('update:modelValue', $event)"
    />
</template>

<script setup>
const emits = defineEmits(['update:modelValue'])

defineProps({
    min: {
        type: Number,
        required: true
    },
    max: {
        type: Number,
        required: true
    },
    modelValue: {
        type: Number,
        required: true
    },
    stepSize: {
        type: Number,
        default: 1
    },
    direction: {
        type: String,
        default: 'horizontal',
        validator: (direction) => ['horizontal', 'vertical'].includes(direction)
    }
})
</script>
