<template>
    <div
        role="option"
        class="survey-step-question-option"
        :class="{ 'survey-step-question-option--selected': isSelected }"
    >
        <v-list-item
            class="d-flex justify-between align-center position-relative"
            tabindex="0"
            @click="emits('selectOption')"
        >
            <span
                :aria-label="text"
                :aria-checked="isSelected"
                role="checkbox"
            >
                {{ text }}
            </span>
            <span
                v-if="isSelected"
                class="survey-step-question-option__icon position-absolute"
            >
                <span
                    class="material-symbols-outlined"
                    data-cy="activity-row-icon"
                >
                    check_circle
                </span>
            </span>
        </v-list-item>
    </div>
</template>

<script setup>
const emits = defineEmits(['selectOption'])

defineProps({
    isSelected: {
        type: Boolean,
        required: true
    },
    text: {
        type: String,
        required: true
    }
})
</script>
