import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'

class ListTransformCarePeriodStatus extends ListTransform {
    constructor(object) {
        object.filterKey = 'carePeriodType'
        super(object)
    }

    // We can only provide a filter config if explicitly configured.
    get getFilterConfig() {
        if (!this.column?.valueFilterOptions) {
            return
        }
        const optionPrefixLen = 'periodType'.length
        const valueLabels = this.column.valueFilterOptions.map(option => {
            const value = option.charAt(optionPrefixLen).toLowerCase() + option.slice(optionPrefixLen + 1)
            const text = Locale.getLanguageItem(option)

            return { value: value, label: text }
        })

        return {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: this.column.sortValueLabels(valueLabels),
            addAll: true
        }
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        const filterValue = keyStrings.length > 0 ? keyStrings[0] : null

        return isGoddard ? { values: [filterValue] } : filterValue
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'care_period_type'
    }
}

export default ListTransformCarePeriodStatus
