import InfoStep from '@model/content/InfoStep'
import Logging from '@serv/Logging'
import moment from 'moment'
import QuestionStep from '@model/content/QuestionStep'
import store from '@/store'

/*
A survey step result object.
this.value can be String or [String] (for multiple selections).
this.valueFloat and this.valueInt are the equivalent numerical values, or NaN.
this.freeTextValue holds any additional free-text, which may be in canonical date/height/weight format.
this.results holds an array of objects, each of which has a type (e.g. textChoice) and value.
*/
class StepResult {
    constructor(object) {
        object = object || {}

        this.id = object.id || object.stepResultPrmId
        this.type = 'stepResult' // in mobile app, all StepResults have this type

        // NOTE: Code uses both stepSlug and contentSlug, so we must set both properties to be the same value.
        if (object.contentSlug && object.results) {
            // Deserialising from full DB ActivityResult
            this.stepSlug = object.contentSlug
            this.contentSlug = object.contentSlug

            this.startTime = object.startTime
            this.endTime = object.endTime
        } else {
            // Deserialising from report
            if (object.textValue === null) {
                object.textValue = undefined // report may send 'null', we want 'undefined'
            }
            const stepSlug = object.contentSlug || object.questionSlug || object.stepSlug
            this.stepSlug = stepSlug
            this.contentSlug = stepSlug

            this.resultPrmId = object.resultPrmId // used to match with SurveyResult
            // this.startTime = object.startTime
            this.endTime = object.endTime
        }

        // Result values
        if (object.results) {
            // Parsing from BE GET
            this.setResultsFromJson(object.results)
        } else {
            this.setResultValue(object.value || object.answerValue || object.answer)
            this.freeTextValue = object.freeTextValue || object.textValue
        }

        // Allow the StepResult to understand the condition for multi-selection results to be complete
        this.minSelected = object.minSelected

        // this.time is a string, and can be used directly on amCharts DateAxis
        this.time = object.endTime
    }

    get moment() {
        return moment(this.time)
    }

    // Set 'value' and 'freeTextValue' correctly from BE results array
    setResultsFromJson(results) {
        results.forEach(result => {
            if (store.state.content.content[this.contentSlug].isMultiSelect && result.type === 'textChoice') {
                result.type = 'multiTextChoice'
            }

            switch (result.type) {
                case 'textChoice':
                    this.setResultValue(result.value)
                    break
                case 'multiTextChoice':
                    this.value = result.values.map(object => object.value)
                    break
                case 'text':
                    this.freeTextValue = result.value
                    break
                case 'integer':
                    this.setResultValue(result.value)
                    break
                default:
                    Logging.error(`Unknown StepResult result type: ${result.type}`)
                    break
            }
        })
    }

    get score() {
        return this.valueInt
    }

    // Return true if:
    // - Multi-select question step with >= minSelected, OR
    // - Any value defined
    get hasSufficientValues() {
        const step = store.state.content.content[this.contentSlug]
        if (step instanceof QuestionStep && step.isMultiSelect) {
            // Multi text choice
            if (step.minSelected == 0) {
                // Even selecting nothing counts as completed
                return true
            }
            if (Array.isArray(this.value)) {
                return this.value.length >= step.minSelected
            }

            return false
        }

        return this.value != undefined
    }

    // Return true only if the StepResult is fully completed.
    // This includes checking for any required free-text value.
    get isComplete() {
        const step = store.state.content.content?.[this.contentSlug]
        if (step && (step instanceof InfoStep || this.hasSufficientValues || step.isPureFreeText)) {
            if (step.requiredTextField != undefined) {
                if (step.choices.length == 1) {
                    return !!this.freeTextValue
                }
                // Require the value only if we've selected the relevant choice
                const choiceIndex = step.choices.findIndex(choice => choice.text == step.requiredTextField)
                // NOTE: the below assumes requiredTextField used only with single-selection steps
                const selectedRequiredChoice =
                    choiceIndex != undefined && step.choices[choiceIndex]?.value == this.value
                if (selectedRequiredChoice) {
                    return !!this.freeTextValue
                }
            }

            return true
        }

        return false
    }

    // Set all result properties to undefined
    clearAllResults() {
        this.setResultValue(undefined)
        this.freeTextValue = undefined
    }

    hasNoResults() {
        return this.value == undefined && this.freeTextValue == undefined
    }

    setStartTime(time) {
        this.startTime = time
    }
    setEndTime(time) {
        this.endTime = time
        this.time = time
    }

    setResultValue(value) {
        this.value = value
        // Calculate numeric values for certain types
        if (value == undefined) {
            this.valueFloat = undefined
            this.valueInt = undefined
        } else {
            this.valueFloat = parseFloat(this.value)
            this.valueInt = Math.round(this.valueFloat)
        }
    }

    // If there is exactly one Result with an actual value, return it, else undefined.
    getSingleValue() {
        if (this.value != undefined && !Array.isArray(this.value)) {
            return this.value
        }
    }

    // If we're adding to a multi-select question, we need to ensure the value is an array
    addMultipleResults(object) {
        if (this.minSelected === undefined) {
            if (!this.value) {
                this.value = []
            } else if (typeof this.value === 'string') {
                this.value = [this.value]
            }

            this.value.push(object.answerValue)
        }
    }
}

export default StepResult
