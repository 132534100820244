<template>
    <SurveyStepWrapper>
        <template #content>
            <SurveySlider v-bind="{...props}" />
        </template>
    </SurveyStepWrapper>
</template>

<script setup>
import SurveySlider from '@comp/survey/SurveySlider.vue'
import SurveyStepWrapper from '@comp/survey/SurveyStepWrapper.vue'


const props = defineProps({
    min: {
        type: Number,
        default: 0
    },
    max: {
        type: Number,
        default: 0
    },
    isQuestionStepFreeTextValue: {
        type: Boolean,
        default: false
    },
    direction: {
        type: String,
        default: 'horizontal',
        validator: (direction) => ['horizontal', 'vertical'].includes(direction)
    },
    stepSize: {
        type: Number,
        default: 1
    }
})
</script>
