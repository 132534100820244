import DataService from '@serv/DataService'
import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import moment from 'moment'
import Utils from '@serv/Utils'

class ListTransformBundleEndDate extends ListTransform {
    constructor(object) {
        super(object)
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        const config = {
            title: Locale.getLanguageItem('patientListBundleEnd'),
            valueLabels: [
                {
                    value: 'before',
                    label: Locale.getLanguageItem('patientListFilterBeforeToday')
                },
                {
                    value: 'after',
                    label: Locale.getLanguageItem('patientListFilterAfterToday')
                }
            ],
            addAll: true
        }

        return config
    }

    sortRows(rows) {
        const momentFuture = new moment(Utils.dateFarFuture)
        rows.sort((a, b) => {
            const columnIndex = this.column.columnIndex
            const valueA = a.listColumns[columnIndex].moment || momentFuture
            const valueB = b.listColumns[columnIndex].moment || momentFuture

            return valueA.diff(valueB)
        })
    }

    reverse(rows) {
        DataService.reverseRowsByDate(rows, this.column)
    }

    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        const columnIndex = this.column.columnIndex
        const filterString = filterStrings[0]
        const nowMoment = new moment()
        if (filterString == 'after') {
            const filtered = rows.filter(row => {
                const rowMoment = row.listColumns[columnIndex].moment

                return nowMoment < rowMoment
            })

            return filtered
        }
        if (filterString == 'before') {
            const filtered = rows.filter(row => {
                const rowMoment = row.listColumns[columnIndex].moment

                return nowMoment > rowMoment
            })

            return filtered
        }
    }

    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        if (!isGoddard || keyStrings.length <= 0) {
            return null
        }

        const filter = keyStrings[0]
        const today = new moment().format(Utils.serialisedDateFormat)

        return {
            dateRange: {
                startDate: filter == 'before' ? null : today,
                endDate: filter == 'after' ? null : today
            }
        }
    }
}

export default ListTransformBundleEndDate
