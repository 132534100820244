import _ from 'lodash'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import { nanoid } from 'nanoid/non-secure'
import StringHelper from '@serv/StringHelper'
import Utils from '@serv/Utils'

// Default duration of notification, in ms
const notifyServiceDefaultTimeoutMs = 5000

/**
 * The Notify App is composed of the NotifyService and NotifyMsg,
 * with the first encapsulating the main logic and being as much as possible framework agnostic,
 * and the latter being a Vue component rendering notifications.
 */
class NotifyService {
    constructor() {
        // We must initialise this to some sort of array, to avoid possible JS errors
        // if our class functions get called BEFORE init() has been called from a Vue
        // component.
        this.Queue = []
        this.iconMap = {
            success: 'check',
            info: 'info',
            error: 'warning'
        }
    }

    /**
     * Initialise the service with the queue array.
     *
     * How does this work? The queue array passed in by the Vue
     * instance is not a plain JS array but a Vue observable.
     * When we modify the object in here the changes will reflect
     * inside the Vue instance as well - as long as we don't overwrite
     * the initial array with something else. So we need to use methods such as
     * push and splice which modify an array _in place_ for this to work.
     */
    init(queueArray) {
        this.Queue = queueArray
    }

    /**
     * Generate UID to use as message id.
     * @private
     */
    _uid() {
        return nanoid()
    }

    /**
     * Push message to queue.
     * @private
     */
    _push(msg, type, duration) {
        const uid = this._uid()
        if (this._findMsg(uid)) {
            return
        }
        const notifObj = {
            uid,
            msg,
            type,
            icon: this.iconMap[type],
            expire: () => this.expireNow(uid),
            _timeoutID: this.expire(uid, duration)
        }
        this.Queue.push(notifObj)

        return uid
    }

    /**
     * Expire message with UID.
     * This is also available on the message instance.
     * @param {String} UID
     * @param {Number} timeout
     */
    expire(uid, timeout = notifyServiceDefaultTimeoutMs) {
        return window.setTimeout(() => this.expireNow(uid), timeout)
    }

    _findMsg(uid) {
        return this.Queue.find(m => m.uid === uid)
    }

    expireNow(uid) {
        const notifObj = this._findMsg(uid)
        if (!notifObj) {
            return
        }
        const idx = this.Queue.indexOf(notifObj)
        this.Queue.splice(idx, 1)
    }

    /**
     * Set message as sticky (will not expire)
     */
    sticky(uid) {
        const message = this._findMsg(uid)

        if (!message) {
            return
        }

        window.clearTimeout(message._timeoutID)
    }

    /**
     * Tries to convert object or array message to text so that
     * it can be displayed in the Notification.
     * @private
     */
    _msg_to_text(msg) {
        if (Utils.isPlainObject(msg) || Array.isArray(msg)) {
            return _(msg).values().first().toString().replace(/"/g, '')
        }

        return msg
    }

    /**
     * Push a message of type 'success', 'error' or 'info.
     * delay = millisecond delay before the message appears
     * duration = millisecond duration the message appears for
     */
    _pushMessageOfTypeWithDelayAndDuration(msg, type, delay = 0, duration = notifyServiceDefaultTimeoutMs) {
        if (delay) {
            window.setTimeout(() => this._push(this._msg_to_text(msg), type, duration), delay)
        } else {
            return this._push(this._msg_to_text(msg), type, duration)
        }
    }

    // Push a success message
    success(msg, delay, duration) {
        return this._pushMessageOfTypeWithDelayAndDuration(msg, 'success', delay, duration)
    }

    // Push an error message
    error(msg, delay, duration) {
        return this._pushMessageOfTypeWithDelayAndDuration(msg, 'error', delay, duration)
    }

    // Get full readable text from an error response
    getErrorTextFromResponse(response, addKey = false) {
        if (!response) {
            return `(${Locale.getLanguageItem('httpErrorNoResponseProvided')})`
        }
        const data = response.data
        const specialKeys = ['message']
        let text
        if (response.status == 500) {
            text = Locale.getLanguageItem('httpError500')
        } else if (data) {
            if (Array.isArray(data.errorCode) && data.errorCode.length > 0) {
                return this.getLocalisedErrorCode(data.errorCode[0])
            }

            if (data.errors && data.errors.length >= 1) {
                text = data.errors[0]
            }
            // If any non-special key has an array value, use first element
            const keyMessages = []
            for (let key of Object.keys(data)) {
                if (!specialKeys.includes(key) && Array.isArray(data[key]) && data[key].length > 0) {
                    const value = data[key][0]
                    const errorString = value.endsWith('.') ? value : `${value}.`

                    if (!addKey) {
                        keyMessages.push(errorString)

                        continue
                    }

                    const keyLabel = Locale.getLanguageItemOrUndefined(`form${StringHelper.capitalize(key)}`)
                    // If there are no translation in dashString - show error string from backend.
                    keyMessages.push(keyLabel ? `${key}: ${errorString}` : errorString)
                }
            }
            if (keyMessages.length > 0) {
                text = keyMessages.join(' ')
            }
            text = text || data.message
        }

        return text
    }

    getLocalisedErrorCode(code) {
        if (!code.startsWith('error')) {
            code = `error${StringHelper.capitalize(code)}`
        }

        const errorMessage = Locale.getLanguageItemOrUndefined(code)
        if (!errorMessage) {
            Logging.error(`Missing translation for error code: ${code}`)

            return code
        }

        return errorMessage
    }

    // Push an error message from axios error response
    errorWithResponse(response, delay, duration) {
        const text = this.getErrorTextFromResponse(response)
        if (text) {
            return this.error(text, delay, duration)
        }
    }

    // Push an info message
    info(msg, delay, duration) {
        return this._pushMessageOfTypeWithDelayAndDuration(msg, 'info', delay, duration)
    }
}

export default new NotifyService()
