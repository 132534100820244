<template>
    <div v-html="markdown.render(source)" />
</template>

<script setup>
import MarkdownIt from 'markdown-it'

const props = defineProps({
    source: {
        type: String,
        required: true
    },
    options: {
        type: Object,
        default: () => ({})
    }
})

const markdown = new MarkdownIt(props.options)
</script>
