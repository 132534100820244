import { checkUser } from '@/mixins/LoginMixin'
import { defineStore } from 'pinia'
import Storage from '@serv/Storage'
import { useLocalStorage } from '@vueuse/core'
import { UserTypeOptional } from '@/models/UserType'
import { useStore } from 'vuex'

const localStorageOptions = {
    serializer: {
        read: (value: string) => {
            return value ? JSON.parse(value) : null
        },
        write: (value: unknown) => {
            return JSON.stringify(value)
        }
    }
}

export const useImpersonationStore = defineStore('impersonation', () => {
    const impersonatedUser = useLocalStorage<UserTypeOptional | null>('impersonatedUser', null, localStorageOptions)
    const originalUser = useLocalStorage<UserTypeOptional | null>('originalUser', null, localStorageOptions)
    const justFinishedImpersonation = useLocalStorage('justFinishedImpersonation', false, localStorageOptions)
    const isInitialReloadPending = useLocalStorage('isInitialReloadPending', false, localStorageOptions)

    const store = useStore()
    async function impersonateUser(user: UserTypeOptional) {
        originalUser.value = store.getters.user
        impersonatedUser.value = user
        Storage.set('spoof', true)
        isInitialReloadPending.value = true
        await checkUser({ impersonatedUser: user })
    }

    async function stopImpersonating() {
        impersonatedUser.value = null
        originalUser.value = null
        Storage.set('spoof', false)
        await checkUser()
        justFinishedImpersonation.value = true
    }

    function $reset() {
        /** this is not exactly part of resetting the store state, however as
         * the store will reset to "not impersonating" state, best to ensure
         * `spoof` is false */
        Storage.set('spoof', false)
        impersonatedUser.value = null
        originalUser.value = null
        justFinishedImpersonation.value = false
    }

    return {
        impersonatedUser,
        originalUser,
        justFinishedImpersonation,
        impersonateUser,
        stopImpersonating,
        isInitialReloadPending,
        $reset
    }
})
