import * as Sentry from '@sentry/vue'
import Auth from '@serv/Auth'
import Config from '@serv/Config'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import NotifyService from '@serv/NotifyService'
import Redirect from '@serv/Redirect'
import ResourceService from '@serv/ResourceService'
import { UserTypeOptional } from '@/models/UserType'
import Whoami from '@serv/Whoami'

export function checkUser({
    spoof,
    redirectPath,
    impersonatedUser
}: {
    spoof?: boolean
    redirectPath?: string
    impersonatedUser?: UserTypeOptional
} = {}) {
    return Whoami.getActiveUser(impersonatedUser)
        .then((user: UserTypeOptional) => {
            if (Config.version && Config.environment == 'production') {
                Sentry.setTag('dashVersion', Config.version)
            }

            if (spoof) {
                Sentry.setTag('Spoof', true)
            }
            if (user.isPatient && !user.isTest) {
                NotifyService.error(Locale.getLanguageItem('loginUnauthorisedFromFrontEnd'))
                setTimeout(() => {
                    Redirect.gotoName('Logout', undefined, { expired: 1 })
                }, 2000)
            } else {
                if (user.isAdmin && !Auth.region) {
                    NotifyService.error(
                        'Logged in as admin but without specifying region - you will not connect to any DB.',
                        400
                    )
                } else if (user.isAdmin) {
                    NotifyService.success(`Logged in as admin, connected to ${Auth.region} region.`, 400)
                } else {
                    NotifyService.success(Locale.getLanguageItem('accountLogInSuccess'), 400)
                }
                dispatchData(user)

                /**
                 * if redirectPath contains query parameters, vue-router will just drop them when using
                 * .push({ path, params, query }) while .push('path?q=test') is working
                 * https://github.com/vuejs/vue-router/issues/3374
                 */
                if (redirectPath) {
                    Redirect.router.push(decodeURIComponent(redirectPath))
                } else {
                    redirect(redirectPath)
                }
            }
        })
        .catch(error => Logging.error(error))
}

export function dispatchData(user: UserTypeOptional) {
    ResourceService.requestAll(user)
}

export function redirect(redirectPath?: string) {
    if (redirectPath) {
        Redirect.gotoPath(redirectPath)
    } else {
        Whoami.getNavigation()
            .then(perms => {
                Redirect.gotoName(perms.HomePage)
            })
            .catch(error => Logging.log(error))
    }
}
