<template>
    <SurveyStepWrapper>
        <template #content>
            <div class="survey-step-question">
                <v-list
                    v-if="shouldShowChoices"
                    :aria-label="getText"
                >
                    <SurveyStepQuestionOption
                        v-for="(choice, index) in step.choices"
                        :key="index"
                        :text="choice.text"
                        :is-selected="choicesSelected[index]"
                        @select-option="() => onChoiceClicked(index)"
                    />
                </v-list>
                <template v-if="showFreeTextArea">
                    <p
                        v-if="requiredTextLabel"
                        class="mt-4 survey-question-step__free-text-label px-4"
                    >
                        {{ requiredTextLabel }}
                    </p>
                    <div class="survey-question-step__free-text w-100 px-4">
                        <textarea
                            class="w-100 pa-2"
                            rows="4"
                            :placeholder="getLangItem('patientSurveyTextareaPlaceholder')"
                            v-model="stepResult.freeTextValue"
                        />
                    </div>
                </template>
                <MrDatePicker
                    v-if="showFreeTextDate && dateFormat"
                    :date-format="dateFormat"
                    name="date"
                    :teleport="false"
                    :placeholder="getLangItem('patientSurveyFieldPlaceholder')"
                    :model-value="stepResult.freeTextValue"
                    @update:model-value="(v) => stepResult.freeTextValue = v"
                />
                <SurveySlider
                    v-if="showFreeTextHeight || showFreeTextWeight"
                    :format="showFreeTextHeight ? getMetricHeightInAllUnits : getMetricWeightInAllUnits"
                    :min="minValue"
                    :max="maxValue"
                    :initial-value="initialValue"
                    :is-small="!!getImageBlobUrlFull"
                    :is-question-step-free-text-value="isQuestionStepFreeTextValue"
                    :step-size="stepValue"
                />
            </div>
        </template>
    </SurveyStepWrapper>
</template>

<script>
import MrDatePicker from '@comp/MrDatePicker.vue'
import StringHelper from '@serv/StringHelper'
import SurveySlider from '@comp/survey/SurveySlider.vue'
import SurveySliderMixin from '@mixins/SurveySliderMixin'
import SurveyStepMixin from '@mixins/SurveyStepMixin'
import SurveyStepQuestionOption from '@comp/survey/SurveyStepQuestionOption.vue'
import SurveyStepWrapper from '@comp/survey/SurveyStepWrapper.vue'
import TaskHandler from '@serv/TaskHandler'
import { useSurveyStepImage } from '@composables/useSurveyStepImage.js'

export default {
    name: 'SurveyStepQuestion',
    inject: ['$bus', '$config'],
    mixins: [SurveySliderMixin, SurveyStepMixin],
    components: {
        SurveySlider,
        MrDatePicker,
        SurveyStepQuestionOption,
        SurveyStepWrapper
    },
    setup() {
        const { getImageBlobUrlFull } = useSurveyStepImage()

        return {
            getImageBlobUrlFull
        }
    },
    data() {
        return {
            // Array matching the Step.choices, with boolean state of each
            choicesSelected: [],
            showFreeTextArea: false,
            showFreeTextDate: false,
            dateFormat: undefined,
            showFreeTextHeight: false,
            showFreeTextWeight: false,
            sliderValue: undefined
        }
    },
    computed: {
        getDelegate() {
            return this
        },
        isMulti() {
            return this.step.minSelected != undefined
        },
        shouldShowChoices() {
            return !this.step.isPureFreeText
        },
        // For height/weight slider - display metric and imperial value
        getMetricHeightInAllUnits() {
            return StringHelper.getMetricHeightInAllUnits
        },
        getMetricWeightInAllUnits() {
            return StringHelper.getMetricWeightInAllUnits
        },
        // For height/weight slider - step value
        stepValue() {
            return this.showFreeTextHeight ? 0.01 : 1
        },
        choiceIndex() {
            let choiceIndex
            if (this.step.optionalTextField != undefined) {
                choiceIndex = this.step.optionalTextFieldChoiceIndex || 0
            } else if (this.step.requiredTextField != undefined) {
                choiceIndex = this.step.requiredTextFieldChoiceIndex || 0
            }

            return choiceIndex
        },
        requiredTextLabel() {
            return this.taskHandler.currentStep.requiredTextField || ''
        }
    },
    methods: {
        onChoiceClicked(choiceIndex) {
            if (this.taskHandler.isSkipping) {
                return
            }
            if (this.isMulti) {
                if (this.choicesSelected[choiceIndex]) {
                    // Always allow unselection
                    this.choicesSelected[choiceIndex] = false
                } else {
                    // Bound num selected
                    const numSelected = this.choicesSelected.filter(x => x).length
                    if (numSelected < this.step.maxSelected) {
                        this.choicesSelected[choiceIndex] = true
                    }
                }
            } else {
                // Toggle this one, turn all others off
                this.choicesSelected.forEach((value, i) => {
                    this.choicesSelected[i] =
                        i == choiceIndex ? !this.choicesSelected[i] : false
                })
            }
            this.updateResult()
            this.updateShowFreeText()
            this.taskHandler.calcNextButtonStatus()
            // Called AFTER state fully updated
            this.$bus.$emit('surveyQuestionStepChoice')
            this.handleAutoNext()
        },
        handleAutoNext() {
            // Auto skip to next step?
            if (
                this.taskHandler.isAutoNext &&
                this.taskHandler.nextButtonStatus == TaskHandler.NextButtonStatus.next &&
                !this.isMulti &&
                !this.step.optionalTextField &&
                !this.step.requiredTextField &&
                !this.taskHandler.isLastStep
            ) {
                this.taskHandler.isSkipping = true // prevent input during delay
                const _this = this
                setTimeout(() => {
                    _this.taskHandler.isSkipping = false
                    // Sending a DOM click event means we get any button animation
                    // _this.$refs.nextButton.click()
                    // This works, but we still do not see the animation
                    const elem = document.getElementById('nextButton')
                    elem.click()
                    //this.taskHandler.nextStep()
                }, 300)
            }
        },
        updateResult() {
            const selectedChoiceValues = this.choicesSelected
                .map((value, i) => {
                    return value ? this.step.choices[i].value : undefined
                })
                .filter(x => x)
            if (this.isMulti) {
                this.stepResult.setResultValue(selectedChoiceValues)
            } else {
                const choice =
                    selectedChoiceValues.length == 1
                        ? selectedChoiceValues[0]
                        : undefined
                this.stepResult.setResultValue(choice)
            }
        },
        updateShowFreeText() {
            if (this.taskHandler.isSkipping) {
                return
            }

            if (this.choiceIndex != undefined) {
                const dateTypes = ['date', 'yyyy-mm-dd', 'yyyy-mm', 'yyyy']
                const specialValues = [...dateTypes, 'height', 'weight']
                this.showFreeTextArea = this.choicesSelected[this.choiceIndex]
                if (
                    this.showFreeTextArea &&
                    specialValues.includes(this.step.choices[this.choiceIndex].value)
                ) {
                    this.showFreeTextArea = false
                    if (dateTypes.includes(this.step.choices[this.choiceIndex].value)) {
                        this.initAsDate(this.step.choices[this.choiceIndex].value)
                    } else if (this.step.choices[this.choiceIndex].value == 'height') {
                        this.initAsHeight()
                    } else if (this.step.choices[this.choiceIndex].value == 'weight') {
                        this.initAsWeight()
                    }
                }

                return
            }
            this.showFreeTextArea = false
            this.showFreeTextDate = false
            this.showFreeTextHeight = false
            this.showFreeTextWeight = false
        },
        initAsDate(dateFormat) {
            this.dateFormat = dateFormat
            this.showFreeTextDate = true
        },
        initAsHeight() {
            this.showFreeTextHeight = true
            this.minValue = 0.05 // m
            this.maxValue = 4 // m
            this.initialValue = 2 // m
            this.isQuestionStepFreeTextValue = true
        },
        initAsWeight() {
            this.showFreeTextWeight = true
            this.isQuestionStepFreeTextValue = true
            this.minValue = 40 // kg
            this.maxValue = 280 // kg
            this.initialValue = undefined
            this.isQuestionStepFreeTextValue = true
        }
    },
    created() {
        // Initialise state from result
        if (this.isMulti) {
            if (Array.isArray(this.stepResult.value)) {
                const choiceIndices = []
                this.step.choices.forEach((choice, i) => {
                    if (this.stepResult.value.includes(choice.value)) {
                        choiceIndices.push(i)
                    }
                })
                this.choicesSelected = Array.from(
                    { length: this.step.choices.length },
                    (_, i) => choiceIndices.includes(i)
                )
            } else {
                this.choicesSelected = new Array(this.step.choices.length).fill(false)
            }
        } else {
            if (this.stepResult.value != undefined) {
                // All array elements false, except the one that matches the stored value
                let choiceIndex = 0
                for (; choiceIndex < this.step.choices.length; choiceIndex++) {
                    if (this.step.choices[choiceIndex].value == this.stepResult.value) {
                        break
                    }
                }
                this.choicesSelected = Array.from(
                    { length: this.step.choices.length },
                    (_, i) => i == choiceIndex
                )
            } else {
                this.choicesSelected = new Array(this.step.choices.length).fill(false)
            }
        }
        // Initialise value if a single choice with free text
        if (this.step.isPureFreeText) {
            this.choicesSelected[0] = true
            this.updateResult()
        }
        this.updateShowFreeText()
    },
    watch: {
        'stepResult.freeTextValue': function () {
            this.taskHandler.calcNextButtonStatus()
        }
    }
}
</script>
