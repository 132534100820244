import _ from 'lodash'
import BaseContent from '@model/content/BaseContent'
import ExerciseRoutine from '@model/content/ExerciseRoutine'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment'
import store from '@/store'

import Utils from '@serv/Utils'

/**
 * Helpers for exercises.
 */
class ExerciseService {
    get activeDaysEnums() {
        return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    }
    get activeDaysStringKeys() {
        return ['dowMonday', 'dowTuesday', 'dowWednesday', 'dowThursday', 'dowFriday', 'dowSaturday', 'dowSunday']
    }
    get kgToLb() {
        return 2.20462
    }
    get intensities() {
        return ['low', 'medium', 'high']
    }
    get libraryRoutine() {
        return Object.values(store.state.content.content).find(
            content => content.type == BaseContent.Type.exerciseRoutine && content.tags.includes('library-routine')
        )
    }
    get libraryExerciseSlugs() {
        return this.libraryRoutine.exerciseSettings.map(settings => settings.stepSlug)
    }
    get libraryExercises() {
        return this.libraryRoutine.exerciseSettings
            .map(settings => store.state.content.exercises[settings.stepSlug])
            .filter(settings => settings)
    }

    // Convert activeDays enum array to bool array.
    // undefined => all days inactive.
    activeDaysEnumsToBools(enums) {
        const bools = new Array(7)
        if (enums == undefined) {
            bools.fill(false)
        } else {
            bools.fill(false)
            enums.forEach(value => {
                const index = this.activeDaysEnums.indexOf(value)
                if (index < 0) {
                    Logging.error(`"${value}" is not a valid activeDays enum`)
                } else {
                    bools[index] = true
                }
            })
        }

        return bools
    }

    // Convert activeDays bool array to enum array.
    // undefined => all days inactive.
    activeDaysBoolsToEnums(bools) {
        if (bools == undefined) {
            return []
        }

        if (bools.length != 7) {
            Logging.error(`bools list is not length 7: ${bools}`)
        }

        const enums = []
        const _this = this
        bools.forEach(function (value, i) {
            if (value) {
                enums.push(_this.activeDaysEnums[i])
            }
        })

        return enums
    }

    isWithinRange(integer, min, max) {
        return integer >= min && integer <= max
    }

    /**
     * Initialise a text field from stored reps and duration integer values.
     * Return as reps (integer), duration in seconds (with 'secs' suffix), duration in minutes (with 'mins' suffix), or empty string.
     */
    textFromRepsDuration(reps, duration) {
        if (reps != undefined) {
            return `${reps} reps`
        }

        if (duration != undefined) {
            if (!(duration % 60)) {
                return `${duration / 60} mins`
            }

            return `${duration} secs`
        }

        return ''
    }

    /**
     * Validate reps/duration from text input.
     * Returns an object with the following properties:
     * reps: integer or undefined
     * duration: integer (seconds) or undefined
     * text: santised text field
     * error: any error message
     */
    validateRepsDurationText(repsduration) {
        const trimmed = repsduration.trim()
        if (!trimmed.length) {
            // Field empty - set backing value to undefined
            return {
                reps: undefined,
                duration: undefined,
                text: ''
            }
        }

        if (trimmed.endsWith('m') || trimmed.includes('min')) {
            // Specified duration in minutes (max 180 mins)
            const integer = parseInt(trimmed)
            if (!isNaN(integer) && this.isWithinRange(integer, 0, 180)) {
                // Valid duration in minutes
                return {
                    duration: integer * 60,
                    reps: undefined,
                    text: `${integer} mins`
                }
            }
        } else if ((!trimmed.endsWith('reps') && trimmed.endsWith('s')) || trimmed.includes('sec')) {
            // Specified duration in seconds (max 180 secs)
            const integer = parseInt(trimmed)
            if (!isNaN(integer) && this.isWithinRange(integer, 0, 180)) {
                // Valid duration in seconds
                return {
                    duration: integer,
                    reps: undefined,
                    text: `${integer} secs`
                }
            }
        } else {
            // Assume specified reps (max 120)
            const integer = parseInt(trimmed)
            if (!isNaN(integer) && this.isWithinRange(integer, 0, 120)) {
                // Valid reps
                return {
                    reps: integer,
                    duration: undefined,
                    text: `${integer} reps`
                }
            }
        }

        // Invalid
        return {
            error: Locale.getLanguageItem('exerciseValidationRepsDurationError'),
            text: ''
        }
    }

    /**
     * Initialise a text field from stored tempo string value.
     * Return as tempo string or empty string.
     */
    textFromTempo(tempo) {
        return tempo != undefined ? tempo : ''
    }

    /**
     * Validate tempo from text input.
     * Returns an object with the following properties:
     * tempo: string value to store, or undefined
     * text: santised text field
     * error: any error message
     */
    validateTempoText(tempo) {
        const trimmed = tempo.trim()
        if (!trimmed.length) {
            // Field empty - set backing value to undefined
            return {
                tempo: undefined,
                text: ''
            }
        }
        // We already forbid any characters other than digits or :
        const parts = trimmed.split(':').filter(part => part.length > 0 && this.isWithinRange(parseInt(part), 0, 60))
        if (parts.length == 3) {
            return {
                tempo: trimmed,
                text: trimmed
            }
        }

        // Invalid
        return {
            error: Locale.getLanguageItem('exerciseValidationTempoError'),
            text: ''
        }
    }

    /**
     * Initialise a text field from stored rest integer value.
     * Return as rest (integer) duration in seconds (with 'secs' suffix), or duration in minutes (with 'mins' suffix), or empty string.
     */
    textFromRest(rest) {
        if (rest != undefined) {
            if (!(rest % 60)) {
                return `${rest / 60} mins`
            }

            return `${rest} secs`
        }

        return ''
    }

    /**
     * Validate rest from text input.
     * Returns an object with the following properties:
     * rest: integer (seconds) or undefined
     * text: santised text field
     * error: any error message
     */
    validateRestText(rest) {
        const trimmed = rest.trim()
        if (!trimmed.length) {
            // Field empty - set backing value to undefined
            return {
                rest: undefined,
                text: ''
            }
        }

        if (trimmed.endsWith('m') || ~trimmed.indexOf('min')) {
            // Specified duration in minutes
            const integer = parseInt(trimmed)
            if (!isNaN(integer) && integer > 0) {
                // Valid duration in minutes
                return {
                    rest: integer * 60,
                    text: `${integer} mins`
                }
            }
        } else if (trimmed.endsWith('s') || ~trimmed.indexOf('sec')) {
            // Specified duration in seconds
            const integer = parseInt(trimmed)
            if (!isNaN(integer) && integer > 0) {
                // Valid duration in seconds
                return {
                    rest: integer,
                    text: `${integer} secs`
                }
            }
        }

        // Invalid
        return {
            error: Locale.getLanguageItem('exerciseValidationRestError'),
            text: ''
        }
    }

    /**
     * Initialise a text field from stored load (float) and intensity (string) values.
     * Return as load in kg (with 'kg' suffix), load in lb (with 'lb' suffix), or string intensity ('Low', 'Medium', 'High).
     */
    textFromLoadIntensity(load, intensity) {
        if (intensity != undefined) {
            return Locale.getLanguageItemForModelEnum('exerciseIntensity', intensity)
        }

        if (load != undefined) {
            if (load == Math.floor(load)) {
                return `${load} kg`
            }

            const roundedLb = Math.round(load * this.kgToLb)

            return `${roundedLb} lb`
        }

        return ''
    }

    /**
     * Validate load/intensity from text input.
     * Returns an object with the following properties:
     * intensity: string enum or undefined
     * load: float (kg) or undefined
     * text: santised text field
     * error: any error message
     */
    validateLoadIntensityText(loadintensity) {
        const trimmed = loadintensity.trim()
        if (!trimmed.length) {
            // Field empty - set backing value to undefined
            return {
                intensity: undefined,
                load: undefined,
                text: ''
            }
        }

        if (~trimmed.indexOf('kg') || ~trimmed.indexOf('kilo')) {
            // Specified load in kg
            const integer = parseInt(trimmed)
            if (!isNaN(integer) && this.isWithinRange(integer, 0, 300)) {
                // Valid load in kg
                return {
                    load: integer,
                    intensity: undefined,
                    text: `${integer} kg`
                }
            }
        } else if (~trimmed.indexOf('lb') || ~trimmed.indexOf('pound')) {
            // Specified load in lb
            const integer = parseInt(trimmed)
            if (!isNaN(integer) && this.isWithinRange(integer, 0, 650)) {
                // Valid load in lb
                return {
                    load: integer / this.kgToLb,
                    intensity: undefined,
                    text: `${integer} lb`
                }
            }
        } else {
            // Assume specified intensity
            const lower = trimmed.toLowerCase()
            let intensity
            if (lower == 'low') {
                intensity = 'low'
            } else if (~lower.indexOf('med')) {
                intensity = 'medium'
            } else if (~lower.indexOf('hi')) {
                intensity = 'high'
            }
            if (intensity != undefined) {
                // Valid intensity
                return {
                    intensity: intensity,
                    load: undefined,
                    text: Locale.getLanguageItemForModelEnum('exerciseIntensity', intensity)
                }
            }
        }

        // Invalid
        return {
            error: Locale.getLanguageItem('exerciseValidationLoadIntensityError'),
            text: ''
        }
    }

    /**
     * Get active days readable summary, from array of 7 bools.
     */
    getActiveDaysSummaryFromBools(bools) {
        const activeRanges = Utils.getIndexRangesOfValueInArray(bools, true)
        const stringKeys = this.activeDaysStringKeys
        const parts = []
        activeRanges.forEach(range => {
            let part
            if (range.length == 1) {
                part = Locale.getLanguageItem(stringKeys[range.startIndex]).substr(0, 2)
            } else {
                part = `${Locale.getLanguageItem(stringKeys[range.startIndex]).substr(0, 2)}-${Locale.getLanguageItem(
                    stringKeys[range.endIndex]
                ).substr(0, 2)}`
            }
            parts.push(part)
        })

        return parts.join(',')
    }

    /**
     * Get a set of all tags for the search filter, from the library of exercises.
     * Pass in a map of exercise slug to ExerciseStep.
     */
    getTagsFromExerciseSlugs(slugs) {
        let tags = new Set()
        slugs.forEach(slug => {
            const exercise = store.state.content.exercises[slug]
            exercise.tags.forEach(tag => tags.add(tag))
        })

        return tags
    }

    /**
     * Get the active exercise routine activity (if any) for a patient.
     */
    getPatientActiveExerciseRoutineActivityAtMoment(patient, atMoment, patientJourney) {
        if (!patientJourney) {
            patientJourney = patient.primaryJourney
        }

        if (!patientJourney.scheduleEvents || patientJourney.scheduleEvents.length == 0) {
            patient.rebuildScheduleEvents(true)
        }

        const activeScheduleEvents = patientJourney.scheduleEvents.filter(scheduleEvent => {
            const startMoment = moment(scheduleEvent.startDate)
            const endMoment = moment(scheduleEvent.endDate)

            return startMoment <= atMoment && atMoment < endMoment
        })

        const exerciseRoutineScheduleEvents = activeScheduleEvents.filter(
            scheduleEvent => scheduleEvent.content?.type == BaseContent.Type.exerciseRoutine
        )

        if (exerciseRoutineScheduleEvents.length == 0) {
            return undefined
        }

        if (exerciseRoutineScheduleEvents.length > 1) {
            Logging.warn(`Found > 1 active ExerciseRoutine for patient: ${patient.personaId}`)
        }

        return exerciseRoutineScheduleEvents[0].activity
    }

    /**
     * Get the historical ExerciseRoutineModifier, for the specified ExerciseRoutine activity,
     * at the specified moment. We return the modifier with the most recent date BEFORE the
     * specified moment.
     *
     * If none found, we return the current modifier for the activity.
     */
    getPatientExerciseRoutineModifierAtMoment(patient, activitySlug, atMoment) {
        const historicalModifiers = patient.exerciseRoutineHistoricalModifiers[activitySlug]
        if (!historicalModifiers) {
            Logging.warn(
                `Patient: ${patient.personaId} has no exerciseRoutineHistoricalModifiers for activity: ${activitySlug}`
            )

            return
        }
        // Modifiers array is increasing by date. Find first one BEFORE moment
        let prevModifier
        for (const modifier of historicalModifiers) {
            const modifierMoment = new moment(modifier.date)
            if (modifierMoment <= atMoment) {
                prevModifier = modifier
            } else {
                if (prevModifier) {
                    return prevModifier
                }
            }
        }

        // Return current modifier for activity
        return patient.exerciseRoutineModifiers[activitySlug]
    }

    /**
     * Get a pure text summary of the exercise plan, suitable for copy-pasting.
     * 24/02/21: As requested, change to a single line per exercise.
     */
    getTextSummaryFromModifier(patient, modifier) {
        const lines = []
        const nowMoment = moment()
        lines.push(`${patient.fullName}: Exercise plan`)
        lines.push(`(Snapshot taken at ${nowMoment.format(Utils.readableDateFormat)})`)
        lines.push('')
        lines.push('Exercises:')
        modifier.exerciseSettings.forEach(exerciseSettings => {
            let line = `${exerciseSettings.title}; `
            if (exerciseSettings.sets != undefined) {
                if (exerciseSettings.reps != undefined) {
                    line += `${exerciseSettings.sets} * ${exerciseSettings.reps} reps`
                } else if (exerciseSettings.duration != undefined) {
                    line += `${exerciseSettings.sets} * ${exerciseSettings.duration} secs`
                }
            }
            if (exerciseSettings.rest != undefined) {
                line += `, with rest of ${this.textFromRest(exerciseSettings.rest)} between sets`
            }
            if (exerciseSettings.tempo != undefined) {
                line += `, Tempo: ${exerciseSettings.tempo}`
            }
            if (exerciseSettings.load != undefined) {
                line += `, Load: ${this.textFromLoadIntensity(exerciseSettings.load, undefined)}`
            } else if (exerciseSettings.intensity != undefined) {
                line += `, Intensity: ${this.textFromLoadIntensity(undefined, exerciseSettings.intensity)}`
            }
            let activeDays = this.getActiveDaysSummaryFromBools(
                exerciseSettings.isActiveDaysUndefined ? modifier.activeDays : exerciseSettings.activeDays
            )
            line += `, Active days: ${activeDays}`
            lines.push(line)
        })

        return lines.join('\n')
    }

    /**
     * Create an ExerciseRoutine template to hold all "favourites" for a user.
     */
    createUserFavouritesTemplate(user) {
        const slug = this.getUserFavouritesTemplateSlug(user)
        const template = new ExerciseRoutine({
            slug: slug,
            title: 'Favourites', // never displayed
            tags: [ExerciseRoutine.TemplateType.user, user.slugPrefix, 'template', 'exercise-routine', 'favourites']
        })
        template.exerciseSettings = [] // initially empty

        return template
    }

    getUserFavouritesTemplateSlug(user) {
        const slug = `${user.slugPrefix}-favourites-tpl-routine`

        return slug
    }

    /**
     * Get an ExerciseRoutine template to hold all "favourites" for a user, or undefined if not found.
     */
    getUserFavouritesTemplate(user) {
        const slug = this.getUserFavouritesTemplateSlug(user)

        return store.state.content.content[slug]
    }

    /**
     * Returns ExerciseSettings if defined as a "favourites" template for the specified exercise.ExerciseSettings
     */
    getUserFavouriteExerciseSettingsForStepSlug(user, stepSlug) {
        const template = this.getUserFavouritesTemplate(user)
        if (template != undefined) {
            return template.getExerciseSettingsWithStepSlug(stepSlug)
        }
    }

    /**
     * Returns true if the specified user has a 'favourites' exercise template with ExerciseSettings
     * exactly matching those specified.
     */
    getUserFavouriteMatchingExerciseSettings(user, exerciseSettings) {
        const templateSettings = this.getUserFavouriteExerciseSettingsForStepSlug(user, exerciseSettings.stepSlug)
        if (templateSettings) {
            return templateSettings.isSameAs(exerciseSettings)
        }

        return false
    }

    doesExerciseSettingsMatchLibrarySettings(exerciseSettings) {
        // When exercises are added to the plan, we create an ExerciseSettings object purely from the ExerciseStep.
        // This means all fields are undefined, except slug, title and text.
        // Therefore below we use ExerciseSettings.hasEmptyValues, and check only title and text against the ExerciseStep.
        const exerciseStep = store.state.content.content[exerciseSettings.stepSlug]

        return (
            exerciseSettings.hasEmptyValues &&
            exerciseSettings.title == exerciseStep.title &&
            exerciseSettings.text == exerciseStep.text
        )
    }

    /**
     * Build weekly compliance figures by averaging "compliance" property
     * over all rows within a week date range.
     *
     * Example input object:
     * {
     *   "exerciseDate": "2020-12-01",
     *   "exerciseSlug": "passive-knee-extension",
     *   "groupLabel": "Exercises",
     *   "title": "Passive knee extension",
     *   "sets": 1,  # the PLANNED sets
     *   "units": "reps" OR "secs"
     *   "reps": 10, OR "duration": 30  # the PLANNED reps or duration
     *   "value": 5 # the REPORTED total reps or duration
     * }
     * For each day, for each exercise in the routine, a row is present if
     * - Any value was reported for that exercise on that day, OR
     * - The exercise was planned, but nothing was reported (value = 0)
     *
     * Example output object:
     * {
     *   "period": "2020-12-01",
     *   "compliance": 62.53
     * }
     */
    calculateExerciseResultsReportWeeklyCompliance(dataset) {
        // Get output row object from values
        function _weekEntry(periodMoment, compliance) {
            const fromDate = periodMoment.format(Utils.readableDateFormat)
            const toDate = periodMoment.clone().add(6, 'days').format(Utils.readableDateFormat)
            compliance = Math.round(compliance)
            const label = `${fromDate} - ${toDate}\nCompliance: ${compliance}%`

            return {
                period: periodMoment.format(Utils.serialisedDateFormat),
                periodLabel: label,
                compliance: compliance
            }
        }
        function _getExercisesCompliance(exerciseSlugToTotals) {
            // Calculate weekly compliance value as average over all exercises
            let numerator = 0.0
            let denominator = 0.0
            Object.keys(exerciseSlugToTotals).forEach(exerciseSlug => {
                const values = exerciseSlugToTotals[exerciseSlug]
                if (values.totalPlanned > 0) {
                    numerator += Math.min(1.0, values.totalReported / values.totalPlanned)
                    denominator += 1.0
                }
            })

            return denominator == 0 ? 0 : Math.min(100, (100 * numerator) / denominator)
        }

        // If no data, return empty array
        if (dataset.length == 0) {
            return []
        }
        // Sort rows by increasing date
        dataset.sort((a, b) => a.exerciseDate.localeCompare(b.exerciseDate))

        const weeklyCompliance = []
        const firstMoment = new moment(dataset[0].exerciseDate)
        const rootMoment = Utils.getFirstMondayBeforeOrAt(firstMoment)
        let currWeekIndex = 0

        // Build map of exercise slug to totals for current week being processed
        let exerciseSlugToTotals = {}

        dataset.forEach(row => {
            const rowMoment = new moment(row.exerciseDate)
            const rowWeekIndex = rowMoment.diff(rootMoment, 'weeks')
            const value = row.units == 'reps' ? row.reps : row.duration
            if (rowWeekIndex != currWeekIndex) {
                // Store weekly compliance value and move to next week
                const weekCompliance = _getExercisesCompliance(exerciseSlugToTotals)
                const weekStartMoment = rootMoment.clone().add(currWeekIndex, 'weeks')
                weeklyCompliance.push(_weekEntry(weekStartMoment, weekCompliance))
                currWeekIndex = rowWeekIndex
                exerciseSlugToTotals = {}
            }
            // Log planned and reported value for this exercise
            let exerciseTotals = exerciseSlugToTotals[row.exerciseSlug]
            if (exerciseTotals == undefined) {
                exerciseTotals = {
                    totalPlanned: 0,
                    totalReported: 0
                }
                exerciseSlugToTotals[row.exerciseSlug] = exerciseTotals
            }
            exerciseTotals.totalPlanned += row.sets * value
            exerciseTotals.totalReported += row.value
        })
        const weekCompliance = _getExercisesCompliance(exerciseSlugToTotals)
        const weekStartMoment = rootMoment.clone().add(currWeekIndex, 'weeks')
        weeklyCompliance.push(_weekEntry(weekStartMoment, weekCompliance))

        return weeklyCompliance
    }

    /**
     * Sort an array of exercises alphabetically by title, unprioritising "bespoke" exercises.
     * If the exercise object has had the new property "titleFavourite" added, we use that over "title".
     */
    sortExercises(exercises) {
        const allExercises = exercises.sort((a, b) =>
            (a.titleFavourite || a.title).localeCompare(b.titleFavourite || b.title)
        )
        const libraryExercises = allExercises.filter(exercise => !exercise.tags.includes('bespoke'))
        const bespokeExercises = allExercises.filter(exercise => exercise.tags.includes('bespoke'))

        return [...libraryExercises, ...bespokeExercises]
    }

    /**
     * Filter exercises by title substring.
     * Sort alphabetically, prioritising whole word matches, unprioritising "bespoke" exercises.
     */
    filterExercisesByTitleSubstring(exercises, filterString) {
        // Further filter by title parts
        filterString = (filterString || '').trim().toLowerCase()
        if (!_.isEmpty(filterString)) {
            const user = store.state.user.user
            const keywords = filterString.split(/\s+/) // Split filterString into keywords

            // Add titleFavourite property for any matching ExerciseSettings
            exercises.forEach(exercise => {
                const settings = this.getUserFavouriteExerciseSettingsForStepSlug(user, exercise.slug)
                exercise.titleFavourite = settings ? settings.title : undefined
            })
            exercises = exercises.filter(exercise => {
                const title = (exercise.titleFavourite || exercise.title).toLowerCase()

                return title.includes(filterString) || keywords.every(keyword => title.includes(keyword))
            })

            // Sort, prioritising whole word matches
            let wordMatchedExercises = exercises.filter(exercise => {
                const title = (exercise.titleFavourite || exercise.title).toLowerCase()
                const paddedTitle = ` ${title} `
                const paddedString = ` ${filterString} `

                return paddedTitle.includes(paddedString)
            })
            wordMatchedExercises = this.sortExercises(wordMatchedExercises)
            let wordUnmatchedExercises = exercises.filter(exercise => !wordMatchedExercises.includes(exercise))
            wordUnmatchedExercises = this.sortExercises(wordUnmatchedExercises)
            exercises = [...wordMatchedExercises, ...wordUnmatchedExercises]
        } else {
            exercises = this.sortExercises(exercises)
        }

        return exercises
    }
}

export default new ExerciseService()
