import { computed } from 'vue'
import { useStore } from 'vuex'

export const useSurveyStepImage = () => {
    const store = useStore()
    const taskHandler = computed(() => store.getters.taskHandler)
    const content = computed(() => store.state.content.content)

    const getImageBlobUrlFull = computed(() => {
        let url = undefined
        if (taskHandler.value.currentStep.keyValues.image) {
            const mediaImage = content.value[taskHandler.value.currentStep.keyValues.image]
            if (mediaImage) {
                url = mediaImage.blobUrlFull
            }
        }

        return url
    })

    return {
        getImageBlobUrlFull
    }
}
