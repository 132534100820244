import Locale from '@serv/Locale'
import moment from 'moment'
import Utils from '@serv/Utils'

export function _resolvePatientJourneyListColumnOfType_appointment(column, patientJourney, row) {
    if (row[column.id]?.date && row[column.id]?.type) {
        const typeLabel = Locale.getLanguageItemForModelEnum('appointmentType', row[column.id].type)
        const appointmentDate = moment(row[column.id].date).format(Utils.readableDateFormat)

        column.cellText = `${typeLabel} (${appointmentDate})`
    }
}
