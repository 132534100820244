import Auth from '@serv/Auth/index'
import LoginService from '@serv/LoginService'
import Redirect from '@serv/Redirect'
import UnauthenticatedAuth from '@serv/Auth/UnauthenticatedAuth'
import Whoami from '@serv/Whoami'

export const homePagePatientLoginMiddleware = async (from, to) => {
    if (Auth.hasSession() || UnauthenticatedAuth.hasSession()) {
        const user = await Whoami.getActiveUser()

        if (user && user.isPatient && from.path == '/') {
            Redirect.gotoName('PortalHome')
        }
    }

    if (to.query.regToken) {
        return { name: 'Patient', query: to.query }
    }
}

export const logoutBeforeEnter = async (from, to, next) => {
    await LoginService.logout({ ignoreNotification: true })

    next()
}
