import _ from 'lodash'
import Config from '@serv/Config'
import ConfigManager from '@config/ConfigManager'
import Logging from '@serv/Logging'
import Redirect from '@serv/Redirect'

/**
 * The Analytics service is a wrapper around any 3rd party analytics services.
 * For now, it's a wrapper around VueMultianalytics.
 */
class Analytics {
    // Store reference to Mixpanel object.
    init(ma) {
        this.mp = ma
        this.isLoggingVerbose = false
    }

    /**
     * Establish the logged-in user, passing a User object.
     * Called only from a network success response from Whoami.getActiveUser.
     */
    setUser(user) {
        if (!this.mp) {
            return
        }
        this.mp.identify(user.userId.toString())
        this.props = {
            owner: user.owner.slug,
            persona: user.persona,
            locale: user.locale
        }
    }

    // Send an event, with a name and payload
    sendEvent(eventName, properties) {
        if (!this.mp || ConfigManager.isMockingServer) {
            return
        }

        // Remove properties with undefined values
        const keysToRemoveIfUndefined = ['patientId']
        properties = properties || {}
        keysToRemoveIfUndefined.forEach(key => {
            if (properties.hasOwnProperty(key) && properties[key] === undefined) {
                delete properties[key]
            }
        })

        const currentRoute = Redirect.router.currentRoute.value
        const payload = _.merge(
            properties,
            {
                route: currentRoute.name
            },
            this.props
        )
        if (Config.spoof) {
            payload.spoof = true
        }
        if (Config.version) {
            payload.appVersion = Config.version
        }
        this.mp.track(eventName, payload)
        if (this.isLoggingVerbose) {
            Logging.log(`Send event: ${eventName}, ${JSON.stringify(payload, null, 2)}`)
        }
    }

    // Log out - clear any stored analytics user details.
    logOut() {
        if (this.mp) {
            this.mp.reset()
        }
    }

    /**
     * Log pressing button to begin web surveys.
     * Props is map of patientJourneyId to all survey Activity slugs for that patientJourney.
     */
    logSurveysBegin(patientJourneysSurveys) {
        const props = {}
        for (const pjs of patientJourneysSurveys) {
            props[pjs.patientJourneyId] = pjs.scheduleEvents.map(se => se.activitySlug)
        }
        this.sendEvent('surveysBegin', props)
    }

    /**
     * Log completing (hitting the outro step of) a web survey.
     * Props is map of stepSlug to StepResult.value, and from ScoreSection.section name to value.
     */
    logSurveyComplete(survey, surveyResult) {
        // Each step result slug and (first) value
        const props = {}
        for (const stepResult of surveyResult.stepResults) {
            // Get first value, as string
            props[stepResult.contentSlug] = stepResult.value
        }
        // Score sections
        Object.values(surveyResult.scoreMap).forEach(scoreSection => {
            props[scoreSection.section] = scoreSection.value
        })
        this.sendEvent('surveyComplete', props)
    }
}

export default new Analytics()
