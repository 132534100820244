<template>
    <div
        v-if="isSpoofing"
        class="mr:bg-red mr:text-white mr:p-2 mr:mb-2"
    >
        <p class="mr:min-h-12 mr:my-auto mr:transition-all mr:duration-200 mr:ease-in-out mr:text-center">
            {{ lang('spoofingWarning') }}
        </p>
        <button
            v-if="!isLegacySpoofing"
            class="mr-btn mr:w-2/3 mr:min-w-[85px] mr:px-6 mr:mx-auto mr:block mr:bg-brand-b mr:hover:bg-brand-b-light mr:transition-colors mr:duration-200 mr:ease-in-out mr:rounded-full"
            @click="stopImpersonation"
        >
            {{ lang('adminDashImpersonationStopImpersonatingButton') }}
        </button>
    </div>
</template>

<script setup lang="ts">
import { lang } from '@comp/Filters'
import NotifyService from '@serv/NotifyService'
import Storage from '@serv/Storage'
import { useImpersonationStore } from '@/stores/impersonation'
import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'

const impersonationStore = useImpersonationStore()
const isSpoofing = ref(impersonationStore.impersonatedUser || Storage.get('spoof'))
const isLegacySpoofing = ref(!impersonationStore.impersonatedUser && Storage.get('spoof'))
const router = useRouter()

onMounted(() => {
    if (impersonationStore.isInitialReloadPending) {
        impersonationStore.isInitialReloadPending = false
        NotifyService.success(lang('adminDashImpersonationStartImpersonatingSuccess'), 3000)
    }
    if (impersonationStore.justFinishedImpersonation) {
        impersonationStore.justFinishedImpersonation = false
        NotifyService.success(lang('adminDashImpersonationStopImpersonatingSuccess'), 3000)
    }
})

async function stopImpersonation() {
    await impersonationStore.stopImpersonating()
    NotifyService.success(lang('adminDashImpersonationStopImpersonatingSuccess'))
    window.location.assign(router.resolve({ path: '/' }).href)
}
</script>
