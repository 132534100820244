import Logging from '@serv/Logging'
import Media from '@model/content/Media'

class MediaVideo extends Media {
    constructor(object) {
        super(object)
        this.script = object.script
        this.posterStartImageSlug = object.posterStart.$link
        this.posterEndImageSlug = object.posterEnd.$link
        if (this.posterStartImageSlug == undefined) {
            Logging.error(`MediaVideo ${this.slug} has undefined posterStartImageSlug`)
        }
    }

    resolve(slugToObject) {
        this.posterStartImage = slugToObject[this.posterStartImageSlug]
        if (this.posterStartImage == undefined) {
            Logging.error(`MediaVideo ${this.slug} could not resolve posterStartImage: ${this.posterStartImage}`)
        }
        this.posterEndImage = slugToObject[this.posterEndImageSlug]
        delete this.posterStartImageSlug
        delete this.posterEndImageSlug
    }
}

export default MediaVideo
